import {
  EuiHeader,
  EuiText,
  EuiPageSection,
  EuiBottomBar,
  EuiPanel,
} from "@elastic/eui";
import { ErrorBoundary } from "react-error-boundary";
import { useMemo, useState } from "react";
import { FormBuilder } from "../../components/forms/form-builder";
import { DefaultFormLayout } from "../../components/forms/layout/default-form-layout";

export type FormPlaygroundProps = {};

export const FormPlayground: React.FC<FormPlaygroundProps> = (props) => {
  const [values, setValues] = useState<Record<string, unknown>>({});
  const [values2, setValues2] = useState<Record<string, unknown>>({});
  const fallbackComponent = useMemo(() => {
    return (
      <EuiText>
        An error was encountered. See Development Tools console for details
      </EuiText>
    );
  }, []);
  return (
    <div className="_qubes_page">
      <EuiHeader theme="dark" position="fixed" className="header">
        <EuiText>Form Playground</EuiText>
      </EuiHeader>
      <EuiHeader position="fixed">
        <EuiText>this is the menu</EuiText>
      </EuiHeader>
      <EuiPageSection>
        <EuiText>
          <h1>Input Showcase</h1>
        </EuiText>
        <ErrorBoundary fallback={fallbackComponent}>
          <FormBuilder
            formBuilderOpts={{
              title: undefined,
              subtitle: undefined,
              formItems: [
                {
                  name: "textInputTest",
                  label: "Text Input Test",
                  type: "text",
                },
                {
                  name: "textAreaInputTest",
                  label: "Text Area Input Test",
                  type: "textarea",
                },
                {
                  name: "passwordInputTest",
                  label: "Password Input Test",
                  type: "password",
                },
                {
                  name: "numberInputTest",
                  label: "Number Input Test",
                  type: "number",
                },
                {
                  name: "selectInputTest",
                  label: "Select Input Test",
                  type: "select",
                  options: [
                    {
                      text: "Option 1",
                      value: "1",
                    },
                    {
                      text: "Option 2",
                      value: "2",
                    },
                    {
                      text: "Option 3",
                      value: "3",
                    },
                  ],
                },
                {
                  name: "comboboxInputTest",
                  label: "ComboBox Input Test",
                  type: "combobox",
                  options: [
                    {
                      text: "Option A",
                      value: "A",
                    },
                    {
                      text: "Option B",
                      value: "B",
                    },
                    {
                      text: "Option C",
                      value: "C",
                    },
                  ],
                },
                {
                  name: "checkboxInputTest",
                  label: "Checkbox Input Test",
                  type: "checkbox",
                },
                {
                  name: "toggleInputTest",
                  label: "Toggle Input Test",
                  type: "toggle",
                },
              ],
              onSubmit: (values) => {
                setValues(values);
              },
              formLayoutPlugin: DefaultFormLayout.name,
              validate: undefined,
            }}
          />
        </ErrorBoundary>
        <EuiPanel
          className="currentValues"
          style={{ marginTop: "25px", backgroundColor: "lightblue" }}
        >
          <EuiText>External State Values</EuiText>
          {Object.keys(values).map((key) => {
            return (
              <div key={key}>
                {key} : {JSON.stringify(values[key])}
              </div>
            );
          })}
        </EuiPanel>
      </EuiPageSection>
      <EuiPageSection>
        <EuiText>
          <h1>Validation Showcase</h1>
        </EuiText>
        <ErrorBoundary fallback={fallbackComponent}>
          <FormBuilder
            formBuilderOpts={{
              title: undefined,
              subtitle: undefined,
              formItems: [
                {
                  name: "textInputTest",
                  label: "Text Input Test",
                  type: "text",
                  required: true,
                },
                {
                  name: "textAreaInputTest",
                  label: "Text Area Input Test",
                  type: "textarea",
                  required: true,
                },
                {
                  name: "passwordInputTest",
                  label: "Password Input Test",
                  type: "password",
                  required: true,
                },
                {
                  name: "numberInputTest",
                  label: "Number Input Test",
                  type: "number",
                  required: true,
                  validators: [
                    (currentVal) => {
                      const currentValAsNumber = Number.parseFloat(
                        `${currentVal}`
                      );
                      if (Number.isNaN(currentValAsNumber)) {
                        return {
                          valid: false,
                          message: "Must be a valid number",
                        };
                      }
                      if (currentValAsNumber < 10) {
                        return {
                          valid: false,
                          message: "Must be greater than 10",
                        };
                      }
                      return { valid: true };
                    },
                  ],
                },
                {
                  name: "selectInputTest",
                  label: "Select Input Test",
                  type: "select",
                  required: true,
                  options: [
                    {
                      text: "Option 1",
                      value: "1",
                    },
                    {
                      text: "Option 2",
                      value: "2",
                    },
                    {
                      text: "Option 3",
                      value: "3",
                    },
                  ],
                },
                {
                  name: "comboboxInputTest",
                  label: "ComboBox Input Test",
                  type: "combobox",
                  required: true,
                  options: [
                    {
                      text: "Option A",
                      value: "A",
                    },
                    {
                      text: "Option B",
                      value: "B",
                    },
                    {
                      text: "Option C",
                      value: "C",
                    },
                  ],
                },
                {
                  name: "checkboxInputTest",
                  label: "Checkbox Input Test",
                  type: "checkbox",
                },
                {
                  name: "toggleInputTest",
                  label: "Toggle Input Test",
                  type: "toggle",
                },
              ],
              onSubmit: (values) => {
                setValues2(values);
              },
              formLayoutPlugin: DefaultFormLayout.name,
              validate: undefined,
            }}
          />
        </ErrorBoundary>
        <EuiPanel
          className="currentValues"
          style={{ marginTop: "25px", backgroundColor: "lightblue" }}
        >
          <EuiText>External State Values</EuiText>
          {Object.keys(values2).map((key) => {
            return (
              <div key={key}>
                {key} : {JSON.stringify(values[key])}
              </div>
            );
          })}
        </EuiPanel>
      </EuiPageSection>
      <EuiBottomBar paddingSize="s">this is the footer</EuiBottomBar>
    </div>
  );
};
