import { EuiCheckbox } from "@elastic/eui";
import { FormInputProps } from "../form-input";

export type CheckboxInputProps = Omit<FormInputProps, "formLayout">;

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  formItem,
  formData,
  setValue,
}) => {
  const resolvedValue = (): boolean => {
    const e = formData[formItem.name];
    if (!e) return false;
    else return e as boolean;
  };
  return (
    <div className="checkbox-input">
      <EuiCheckbox
        id={formItem.name}
        checked={resolvedValue()}
        onChange={(evt) => setValue(evt.target.checked)}
      />
    </div>
  );
};
