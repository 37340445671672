


export function undef<A>(): A | undefined {
    return undefined
}



export function memoize<A>(fn: () => A): () => A {
    let memo: A | undefined
    return () => {
        if ( memo === undefined ) {
            memo = fn()
        }
        return memo
    }
}