import { EuiTextArea } from "@elastic/eui";
import { FormInputProps } from "../form-input";

export type TextAreaInputProps = Omit<FormInputProps, "formLayout">;

export const TextAreaInput: React.FC<TextAreaInputProps> = ({
  formItem,
  formData,
  setValue,
}) => {
  const resolvedValue = (): string => {
    const e = formData[formItem.name];
    if (!e) return "";
    else if (typeof e === "object") return JSON.stringify(e);
    else return e as string;
  };
  return (
    <div className="textarea-input">
      <EuiTextArea
        id={formItem.name}
        value={resolvedValue()}
        onChange={(evt) => setValue(evt.target.value)}
      />
    </div>
  );
};
