import { EuiComboBox, EuiComboBoxOptionOption } from "@elastic/eui";
import { FormInputProps } from "../form-input";
import { FormItemOption } from "../form-builder.model";
import { useMemo, useState } from "react";

export type ComboBoxInputProps = {
  options: FormItemOption[];
} & Omit<FormInputProps, "formLayout">;

// TODO -- if the options change, they are not being updated
export const ComboBoxInput: React.FC<ComboBoxInputProps> = ({
  formItem,
  setValue,
  options,
}) => {
  const mappedFormItemOptions: EuiComboBoxOptionOption<string>[] =
    useMemo(() => {
      return options.map((opt) => {
        return { label: opt.text, value: `${opt.value ?? ""}` };
      });
    }, [options]);

  const [comboboxOptions, setComboboxOptions] = useState<
    EuiComboBoxOptionOption<string>[]
  >(mappedFormItemOptions);
  const [selectedOptions, setSelected] =
    useState<EuiComboBoxOptionOption<string>[]>();

  const onChange = (selections: EuiComboBoxOptionOption<string>[]) => {
    setSelected(selections);
    setValue(selections?.map((selection) => selection.value) ?? []);
  };

  const onCreateOption = (
    searchValue: string,
    flattenedOptions: EuiComboBoxOptionOption<string>[]
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      label: searchValue,
    };

    // Create the option if it doesn't exist.
    if (
      flattenedOptions.findIndex(
        (option) => option.label.trim().toLowerCase() === normalizedSearchValue
      ) === -1
    ) {
      setComboboxOptions([...comboboxOptions, newOption]);
    }

    // Select the option.
    setSelected((prevSelected) => [...(prevSelected ?? []), newOption]);
  };

  return (
    <div className="combobox-input">
      <EuiComboBox
        id={formItem.name}
        onCreateOption={onCreateOption}
        selectedOptions={selectedOptions}
        options={comboboxOptions}
        onChange={onChange}
        isClearable={true}
      />
    </div>
  );
};
