import {
  EuiButton,
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPage,
  EuiPageBody,
  EuiPageSection,
  EuiText,
} from "@elastic/eui";
import "./page.scss";
import { useCallback, useMemo, useState } from "react";

import { Page, View } from "../../../model/qubes/qubes-model";
import QubesView from "../view/qubes-view";
import GlobalClient from "../../../hermesclient/client";
import ReactJson from "react-json-view";
import { Button } from "@mui/material";
import { FormBuilderManager } from "../../../components/forms/form-builder-manager";
import { ErrorBoundary } from "react-error-boundary";

// mostRecentProcessRunUid is used for logs
// MinionProcess.mailbox is used for talking to minion

export type QubesPageProps = {
  views: Array<View>;
};

function openRpcViewer() {
  GlobalClient.get()
    .mailbox()
    .then((mbox) => {
      const url = `/devtools/${mbox.readerKey}`;
      window.open(url, "_blank")?.focus();
    });
}

const QubesPage: React.FC<QubesPageProps> = ({ views }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [responseBody, setResponseBody] = useState<object>({});
  const showModelFn = useMemo(() => {
    return (json: object) => {
      setResponseBody(json);
      setIsModalVisible(true);
    };
  }, [setIsModalVisible, setResponseBody]);

  //TOOD load page based on URL
  const page: Page = {
    name: "Services",
    views: views,
  };

  const fallbackComponent = useMemo(() => {
    return (
      <EuiText>
        An error was encountered. See Development Tools console for details
      </EuiText>
    );
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const formBuilderManager = useMemo(() => <FormBuilderManager />, []);

  return (
    <div className="_qubes_page">
      <ErrorBoundary fallback={fallbackComponent}>
        {formBuilderManager}
      </ErrorBoundary>
      {isModalVisible ? (
        <EuiModal onClose={closeModal} style={{ width: 500 }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>A typical modal</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <ReactJson
              src={responseBody}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              quotesOnKeys={false}
              sortKeys={true}
              name={null}
            />
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={closeModal} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      ) : null}
      <EuiHeader theme="dark" className="header" id="page-header">
        <EuiHeaderSection>
          <EuiHeaderSectionItem>React QUBES</EuiHeaderSectionItem>
        </EuiHeaderSection>
        <EuiHeaderSection side="left">
          <EuiHeaderSectionItem>{page?.name}</EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
      <EuiPage>
        <EuiPageBody>
          <EuiPageSection>
            <Button
              onClick={() => {
                openRpcViewer();
              }}
            >
              RPC Viewer
            </Button>
          </EuiPageSection>
          <EuiPageSection>
            {page?.views.map((view) => (
              <QubesView
                view={view}
                key={view?.name}
                showModalFn={showModelFn}
              />
            ))}
          </EuiPageSection>
        </EuiPageBody>
      </EuiPage>
    </div>
  );
};

export default QubesPage;
