import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

/**
 * @property user {`User`} - the currently logged in user, or undefined if no user logged in
 */
export interface ModelState {
  model?: any;
}

/**
 * sets the state of the slice to be an empty object.
 */
const initialState: ModelState = {};

/**
 * allows the accountInfo to be called using just the name
 */
export interface ModelPayload {
  model: any;
}

/**
 * @param {object} createSlice builds an object from the name, initial state, and reducer
 */
export const modelSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    /**
     * Overwrite the current model value, or create one in state if it doesn't exist.
     * @param state is the current state of the slice
     * @param action is the action to take place (create, update, delete)
     */
    setModel(state, action: PayloadAction<ModelPayload>) {
      if (action?.payload?.model) state.model = action.payload.model;
    },
  },
});

export const { setModel: setUser } = modelSlice.actions;

/**
 * Selector to retrieve the model state
 * @param state the application state
 * @returns ModelState
 */
export const modelStateSelector = (state: RootState): ModelState => state.model;

export default modelSlice;
