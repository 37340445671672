import { BrowserRouter, Route, Routes } from "react-router-dom";
import BasePage from "../pages/base/base-page";
import LoginPage from "../pages/login/login-page";
import NotFoundPage from "../pages/not-found/not-found";
import AuthWall from "../components/auth-wall/auth-wall";
import Playground from "../pages/qubes/playground/playground";
import QubesPage from "../pages/qubes/page/page";
import { HermesDevtoolsPage } from "../pages/hermes-devtools/hermes-devtools-page";
import { defaultViews } from "../pages/qubes/view-defs";
import { FormPlayground } from "../pages/form-playground/form-playground";

export default function AppRoutes() {
  const REACT_ROUTER_BASENAME = process.env.REACT_ROUTER_BASENAME || "/";

  return (
    <AuthWall roles={["ActivatedUser"]}>
      <BrowserRouter basename={REACT_ROUTER_BASENAME}>
        <Routes>
          <Route
            path="/login"
            element={
              <BasePage>
                <LoginPage />
              </BasePage>
            }
          />
          <Route
            path="/playground"
            element={
              <BasePage>
                <Playground />
              </BasePage>
            }
          />
          <Route
            path="/form-playground"
            element={
              <BasePage>
                <FormPlayground />
              </BasePage>
            }
          />
          <Route
            path="/page"
            element={
              <BasePage>
                <QubesPage views={defaultViews()} />
              </BasePage>
            }
          />
          <Route
            path="/page/:pageUidOrName"
            element={
              <BasePage>
                <QubesPage views={[]} />
              </BasePage>
            }
          />
          <Route
            path="/devtools/:readerKey"
            element={
              <BasePage>
                <HermesDevtoolsPage />
              </BasePage>
            }
          />
          <Route
            path="*"
            element={
              <BasePage>
                <NotFoundPage />
              </BasePage>
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthWall>
  );
}
