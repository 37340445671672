import { EuiFieldNumber } from "@elastic/eui";
import { FormInputProps } from "../form-input";

export type NumberInputProps = Omit<FormInputProps, "formLayout">;

export const NumberInput: React.FC<NumberInputProps> = ({
  formItem,
  formData,
  setValue,
}) => {
  const resolvedValue = (): string => {
    const e = formData[formItem.name];
    if (!e) return "";
    else return e as string;
  };
  return (
    <div className="number-input">
      <EuiFieldNumber
        id={formItem.name}
        value={resolvedValue()}
        onChange={(evt) => setValue(evt.target.value)}
      />
    </div>
  );
};
