import { EuiSwitch } from "@elastic/eui";
import { FormInputProps } from "../form-input";

export type ToggleInputProps = Omit<FormInputProps, "formLayout">;

export const ToggleInput: React.FC<ToggleInputProps> = ({
  formItem,
  formData,
  setValue,
}) => {
  const resolvedValue = (): boolean => {
    const e = formData[formItem.name];
    if (!e) return false;
    else return e as boolean;
  };
  return (
    <div className="toggle-input">
      <EuiSwitch
        id={formItem.name}
        label=""
        checked={resolvedValue()}
        onChange={(evt) => setValue(evt.target.checked)}
      />
    </div>
  );
};
