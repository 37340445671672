import { Add, Circle, Replay } from "@mui/icons-material";

export type MappedIcons = "Add" | "Replay" | "Default";

export const IconFactory = {
  getIcon(iconName: MappedIcons, onClick?: () => void) {
    switch (iconName) {
      case "Add":
        return <Add onClick={onClick} />;
        break;
      case "Replay":
        return <Replay onClick={onClick} />;
        break;
      default:
        return <Circle onClick={onClick} />;
    }
  },
};
