import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { ExpandableIcon } from "../../assets";

/**
 * Expandable Icon component used to denote that a modal window is expandable
 */
export const ExpandableIconComponent = () => (
  <EuiFlexItem className="expandable-icon-container">
    <EuiFlexGroup justifyContent="flexEnd">
      <EuiFlexItem grow={false}>
        <img
          className="expandable-icon-image"
          src={ExpandableIcon}
          alt="Expandable"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiFlexItem>
);
