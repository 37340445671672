import "./devtools-table.scss";
import { useMemo } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModelUpdate,
  GridRowParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { IconFactory } from "../icon-factory/icon-factory";
import {
  ResolvedTableModel,
  TableModel,
  resolveColumnDef,
  ShowModalFn,
} from "./table-model";
import { GridApiCommunity } from "@mui/x-data-grid/internals";

export type QubesGridProps = {
  tableModel: TableModel<any>;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  showModalFn: ShowModalFn;
};

const QubesGrid3: React.FC<QubesGridProps> = ({
  tableModel,
  apiRef,
  showModalFn,
}) => {
  const resolvedTableModel = useMemo(
    () => new ResolvedTableModel(tableModel),
    [tableModel]
  );

  const rows = useMemo(() => {
    // ??? TODO @Isaiah - how do we tie the lifecycle of the subscription to the lifecycle of the component?
    const realtimeSubscription = tableModel.realtime?.subscribe({
      next: (change) => {
        const rowUpdates = new Array<GridRowModelUpdate>();
        if (change.type === "upsert") {
          const rowId = tableModel.rowId(change.row);
          tableModel.currentRows.set(rowId, change.row);
          rowUpdates.push({ id: rowId, row: change.row });
        } else if (change.type === "delete") {
          rowUpdates.push({ id: change.rowId, _action: "delete" });
        }
        rowUpdates.forEach((rowUpdate) =>
          apiRef.current.updateRows([rowUpdate])
        );
      },
    });
    // const realtimeSubscription =
    //   tableModel.realtime?.pipe(bufferTime(500, null, 20)).subscribe({
    //     next: (changes) => {
    //       const rowUpdates = new Array<GridRowModelUpdate>()
    //       changes
    //         .forEach((change) => {
    //           if (change.type === "upsert") {
    //             const rowId = tableModel.rowId(change.row)
    //             tableModel.currentRows.set(rowId, change.row)
    //             rowUpdates.push({ id: rowId, row: change.row })
    //           } else if (change.type === "delete") {
    //             rowUpdates.push({ id: change.rowId, _action: 'delete' })
    //           }
    //         })
    //         ;
    //       rowUpdates.forEach((rowUpdate) =>
    //         apiRef.current.updateRows([rowUpdate])
    //       )
    //     },
    //   });

    return tableModel.initialRows.map((dataRow, index) => {
      const rowId = tableModel.rowId(dataRow);
      tableModel.currentRows.set(rowId, dataRow);
      return { id: rowId, row: dataRow };
    });
  }, [apiRef, tableModel]);

  const columns: GridColDef<any>[] = useMemo(() => {
    const columnDefs = tableModel.columns.map((col) =>
      resolveColumnDef(tableModel, col)
    );
    if (tableModel.actions) {
      const actionColumn: GridColDef<any> = {
        field: "actions",
        type: "actions",
        getActions: (params: GridRowParams) =>
          tableModel.actions?.map((action) => (
            <GridActionsCellItem
              key={action.name}
              showInMenu={true}
              label={action.name}
              icon={IconFactory.getIcon(action.icon ?? "Default")} //TODO grab this from the action
              onClick={() => {
                const row = tableModel.currentRows.get(params.row.id);
                if (action.apply) {
                  action.apply(tableModel, apiRef.current, row);
                }
                if (action.run) {
                  action.run({
                    tableModel,
                    api: apiRef.current,
                    row,
                    showModalFn,
                  });
                }
              }}
            />
          )) ?? [],
      };
      columnDefs.push(actionColumn);
    }
    return columnDefs;
  }, [apiRef, showModalFn, tableModel]);

  return (
    <DataGrid
      initialState={{
        columns: {
          columnVisibilityModel: resolvedTableModel.columnVisibilityModel,
        },
      }}
      apiRef={apiRef}
      rows={rows}
      getRowId={(tableRow) => tableRow.id}
      columns={columns}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
    />
  );
};

export default QubesGrid3;
