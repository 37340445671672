import { isEmpty } from "lodash";
import { FormItemLayoutProperties, LayoutService } from "./layout-service";
import { EuiFlexGroup, EuiFlexItem, EuiFormRow } from "@elastic/eui";

export const DefaultFormLayout = {
  name: "DefaultFormLayout",
  layoutFormItem: (
    formItemLayoutProperties: FormItemLayoutProperties,
    showErrors?: boolean
  ) => {
    return (
      <EuiFormRow
        label={formItemLayoutProperties.label}
        helpText={formItemLayoutProperties.helperText}
        error={formItemLayoutProperties.errorText}
        isInvalid={!isEmpty(formItemLayoutProperties.errorText)}
      >
        <EuiFlexItem>{formItemLayoutProperties.input}</EuiFlexItem>
      </EuiFormRow>
    );
  },
  layoutForm: (formItems: Record<string, JSX.Element>): JSX.Element => {
    if (isEmpty(formItems)) return <></>;
    return (
      <EuiFlexGroup className={DefaultFormLayout.name} direction="column">
        {Object.keys(formItems).map((key) => {
          return formItems[key];
        })}
      </EuiFlexGroup>
    );
  },
};

LayoutService.get().register(DefaultFormLayout);
