import { EuiPageSection, EuiSpacer, EuiText } from "@elastic/eui";
import "./qubes-view.scss";
import { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { View } from "../../../model/qubes/qubes-model";
import QubesGrid3 from "../../../components/qubes-table/qubes-grid";
import {
  ShowModalFn,
  TableModel,
  createTableModel,
} from "../../../components/qubes-table/table-model";
import { useGridApiRef } from "@mui/x-data-grid";

export type ViewProps = {
  view: View;
  showModalFn: ShowModalFn;
};

const QubesView: React.FC<ViewProps> = ({ view, showModalFn }) => {
  const apiRef = useGridApiRef();

  const [tableModel, setTableModel] = useState<TableModel<any>>();

  useEffect(() => {
    if (!tableModel) {
      if (view.kind === "biql") {
        setTableModel(
          createTableModel({
            biqlQuery: view.biqlQuery,
            actions: view.rowActions ?? [],
          })
        );
      } else {
        setTableModel(view.tableModel);
      }
    }
  }, [tableModel, view]);

  const fallbackComponent = useMemo(() => {
    return (
      <EuiText>
        An error was encountered. See Development Tools console for details
      </EuiText>
    );
  }, []);

  return (
    <div className="_qubes_view">
      <EuiPageSection paddingSize="s">
        <EuiText>
          <h2>{view.name}</h2>
        </EuiText>
      </EuiPageSection>
      <EuiSpacer size="xs" />
      <EuiPageSection paddingSize="s">
        {tableModel ? (
          <ErrorBoundary fallback={fallbackComponent}>
            <QubesGrid3
              tableModel={tableModel}
              apiRef={apiRef}
              showModalFn={showModalFn}
            />
          </ErrorBoundary>
        ) : // <QubesTable biqlQuery={biqlQuery!} response={response} />
        null}
      </EuiPageSection>
    </div>
  );
};

export default QubesView;
