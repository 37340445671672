import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { EuiProvider, euiStylisPrefixer } from "@elastic/eui";
import createCache from "@emotion/cache";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@mui/material";
// When using TypeScript 4.x and above
import type {} from "@mui/x-data-grid/themeAugmentation";
import { createTheme } from "@mui/material";
// import type {} from '@mui/x-data-grid-pro/themeAugmentation';
// import type {} from '@mui/x-data-grid-premium/themeAugmentation';

const theme = createTheme({
  components: {
    // Use `MuiDataGrid` on DataGrid, DataGridPro and DataGridPremium
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // backgroundColor: "red",
        },
      },
    },
  },
});

const cache = createCache({
  key: "codesandbox",
  stylisPlugins: [euiStylisPrefixer],
  container: document.querySelector('meta[name="emotion-styles"]') as Element,
});
cache.compat = true;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <EuiProvider cache={cache}>
        <App />
      </EuiProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
