import { useEffect } from "react";
import "./App.scss";
import AppRoutes from "./routes/route";
import "@elastic/eui/dist/eui_theme_light.css";
import { Provider } from "react-redux";
import { store } from "./state";
import { runHermesClientTest } from "./hermesclient/client";

function App() {
  /**
   * Run only once and log out any build properties
   */
  useEffect(() => {
    const propertiesMap: Record<string, string | undefined> = {
      API_URL: process.env.API_URL,

      PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
      REACT_ROUTER_BASENAME: process.env.REACT_ROUTER_BASENAME,

      VERSION: process.env.VERSION,
    };

    console.log("***** ENVIRONMENT *****");
    Object.keys(propertiesMap).forEach((propKey) => {
      console.log(`${propKey}: ${propertiesMap[propKey]}`);
    });
    console.log("***** ENVIRONMENT *****");
  }, []);

  useEffect(() => {
    runHermesClientTest();
  }, []);

  return (
    <Provider store={store}>
      <div className="_app">
        <AppRoutes />
      </div>
    </Provider>
  );
}

export default App;
