import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, userSelector } from "../../state/slices/user-slice";
import { QubesUserRole } from "../../qubes-client/model/model";
import { QubesAPI } from "../../qubes-client/api";
import { EuiPanel, EuiText } from "@elastic/eui";
import Cookies from "js-cookie";

// Wrap any elements that you want protected, pass roles and message through props
const AuthWall: React.FC<{ children: ReactNode; roles: string[] }> = ({
  children,
  roles,
}) => {
  const dispatch = useDispatch();

  const loginPagePath: string = useMemo(() => `/login`, []);
  const [initialized, setInitialized] = useState(false);

  /**
   * The first time this component is loaded, we want to see if there is already an existing
   * session available. If so, confirm it is active and use it, otherwise route the user to
   * the login page
   */
  useEffect(() => {
    const checkSessUrlParm = async (qd: any): Promise<boolean> => {
      let haveActiveUser = false;
      try {
        QubesAPI.get().setXSESS(qd.sess[0]);
        const user = await QubesAPI.get().ping();
        dispatch(setUser({ user }));
        haveActiveUser = true;
        console.info(`Existing session is active`);
        if (window.location.pathname === loginPagePath)
          window.location.replace("/");
      } catch (err) {
        //the error is already logged, so we'll just log what's relevant to our context
        console.info("The user is not logged in");
        //we also want to clear the item from storage so we don't try to process it again
        window.sessionStorage.removeItem("X-SESS");
      }
      setInitialized(true);
      return haveActiveUser;
    };

    const checkSessCookie = async (sessCookie: string): Promise<boolean> => {
      let haveActiveUser = false;
      console.info(`Existing cookie found | ${sessCookie}`);
      try {
        const user = await QubesAPI.get().ping();
        dispatch(setUser({ user }));
        console.info(`Existing cookie is active`);
        haveActiveUser = true;
      } catch (err) {
        //the error is already logged, so we'll just log what's relevant to our context
        console.info("The user is not logged in");

        Cookies.remove("SESS");
        Cookies.remove("SESS-US"); //remove the user-switch cookie

        //TODO where do we send the user?????
      }
      setInitialized(true);
      return haveActiveUser;
    };

    const checkLocalStorage = async (
      sessStorageToken: string
    ): Promise<boolean> => {
      let haveActiveUser = false;
      console.info(`Existing session found | ${sessStorageToken}`);
      try {
        QubesAPI.get().setXSESS(sessStorageToken);
        const user = await QubesAPI.get().ping();
        dispatch(setUser({ user }));
        console.info(`Existing session is active`);
        if (window.location.pathname === loginPagePath)
          window.location.replace("/");
      } catch (err) {
        //the error is already logged, so we'll just log what's relevant to our context
        console.info("The user is not logged in");
        //we also want to clear the item from storage so we don't try to process it again
        window.sessionStorage.removeItem("X-SESS");
      }
      setInitialized(true);
      return haveActiveUser;
    };

    const checkIfExistingSession = async () => {
      try {
        //Deployed Server path
        let haveActiveUser = false;
        const sessCookie = Cookies.get("SESS");
        const sessStorageToken = window.sessionStorage.getItem("X-SESS");

        // Get the decoded url parms if they exist
        let qd: any = {};
        window.location.search
          ?.substring(1)
          ?.split("&")
          ?.forEach((item) => {
            const k = item.split("=")[0],
              v = decodeURIComponent(item.split("=")[1]);
            if (k in qd) {
              qd[k].push(v);
            } else {
              qd[k] = [v];
            }
          });

        // if we have a sess variable, confirm it is active
        if (qd.sess) {
          haveActiveUser = await checkSessUrlParm(qd);
        }

        if (!haveActiveUser && sessCookie) {
          haveActiveUser = await checkSessCookie(sessCookie);
        }

        //Local Dev Environment Path
        if (!haveActiveUser && sessStorageToken) {
          haveActiveUser = await checkLocalStorage(sessStorageToken);
        }

        if (!sessCookie && !sessStorageToken) {
          console.info("No existing session found");
          setInitialized(true);
        }
      } catch (err) {
        console.error(err);
      }
    };
    checkIfExistingSession();
    //these dependencies should never change so as to be certain this method only runs once
  }, [dispatch, loginPagePath]);

  const user = useSelector(userSelector);

  const access =
    user?.roles && Array.isArray(user?.roles) && user?.roles.length > 0
      ? user?.roles
      : [];

  const granted = access.some((role: QubesUserRole) =>
    roles.find((r) => r === role.name)
  );

  if (!initialized)
    return (
      <EuiPanel grow={false}>
        <EuiText>Initializing...</EuiText>
      </EuiPanel>
    );
  else if (window.location.pathname === loginPagePath || granted) {
    if (!granted) console.debug("already headed to login page");
    return <div className="_auth-wall">{children}</div>;
  } else {
    console.debug("reroute to login page");
    window.location.replace(loginPagePath);
    return <></>;
  }
};

export default AuthWall;
