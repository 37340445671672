import { useEffect, useState } from "react";
import { FormBuilder } from "./form-builder";
import { FormBuilderOptions } from "./form-builder.model";
import { Subject } from "rxjs";
import Modal from "../modal/modal";

export type FormBuilderManagerProps = {};

export const showForm = new Subject<FormBuilderOptions>();

export const FormBuilderManager: React.FC<FormBuilderManagerProps> = (
  props
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentFormProps, setCurrentFormProps] =
    useState<FormBuilderOptions>();

  useEffect(() => {
    const sub = showForm.subscribe((onNext: FormBuilderOptions) => {
      setCurrentFormProps(onNext);
      setIsOpen(true);
    });

    return () => sub.unsubscribe();
  }, []);

  return isOpen && currentFormProps ? (
    <Modal
      id="form-builder-manager"
      scrollContent={true}
      onClose={() => setIsOpen(false)}
    >
      <FormBuilder formBuilderOpts={currentFormProps} />
    </Modal>
  ) : (
    <></>
  );
};
