import { EuiSelect } from "@elastic/eui";
import { FormInputProps } from "../form-input";
import { FormItemOption } from "../form-builder.model";
import { isEmpty } from "lodash";

export type SelectInputProps = {
  options: FormItemOption[];
} & Omit<FormInputProps, "formLayout">;

export const SelectInput: React.FC<SelectInputProps> = ({
  formItem,
  formData,
  setValue,
  options,
}) => {
  const resolvedValue = (): string => {
    const e = formData[formItem.name];
    if (!e) return "";
    else return e as string;
  };
  return (
    <div className="select-input">
      <EuiSelect
        id={formItem.name}
        hasNoInitialSelection={isEmpty(resolvedValue)}
        options={options}
        value={resolvedValue()}
        onChange={(evt) => setValue(evt.target.value)}
      />
    </div>
  );
};
