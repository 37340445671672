import { Subject } from "rxjs";
import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { MappedIcons } from "../icon-factory/icon-factory";
import GlobalClient, {
  CdcTable,
  ChangeDataCaptureEvent,
} from "../../hermesclient/client";
import {
  BiqlQuery,
  CalculatedField,
  Field,
  QubesAPI,
} from "../../qubes-client/api";

export type RowIdT = string;

export type RowUpsert<RowT> = {
  type: "upsert";
  row: RowT;
};

export type RowDelete<RowT> = {
  type: "delete";
  rowId: RowIdT;
};

export type ShowModalFn = (json: object) => void;

export interface ActionCtx<RowT> {
  tableModel: TableModel<RowT>;
  api: GridApiCommunity;
  row: RowT;
  showModalFn: ShowModalFn;
  rowIndex?: number;
  colIndex?: number;
}

export type RowAction<RowT> = {
  name: string;
  icon: MappedIcons;
  apply?: (
    tableModel: TableModel<RowT>,
    api: GridApiCommunity,
    row: RowT,
    rowIndex?: number,
    colIndex?: number
  ) => void;
  run?: (ctx: ActionCtx<RowT>) => Promise<void> | void;
};

export type RowChange<RowT> = RowUpsert<RowT> | RowDelete<RowT>;

export class ResolvedTableModel {
  readonly tableModel: TableModel<any>;
  readonly columnVisibilityModel: GridColumnVisibilityModel;

  constructor(tableModel: TableModel<any>) {
    this.tableModel = tableModel;
    this.columnVisibilityModel = {};
    tableModel.columns.forEach((columnDef) => {
      if (columnDef.muiColDef.field) {
        const visible = !(columnDef.hidden ?? false);
        this.columnVisibilityModel[columnDef.muiColDef.field] = visible;
      }
    });
  }
}

export interface TableModel<RowT> {
  columns: ColumnDef<RowT>[];
  initialRows: RowT[];
  rowId(row: RowT): RowIdT;
  realtime?: Subject<RowChange<RowT>>;
  actions?: RowAction<RowT>[];
  currentRows: Map<RowIdT, RowT>;
}

export interface ColumnDef<RowT> {
  muiColDef: GridColDef;
  hidden?: boolean;
  valueGetter?: (row: RowT) => any;
  renderCell?: (row: RowT) => React.ReactNode;
}

export function resolveColumnDef<RowT>(
  tableModel: TableModel<RowT>,
  columnDef: ColumnDef<RowT>
): GridColDef {
  const gcd = { ...columnDef.muiColDef };
  const valueGetterFn = columnDef.valueGetter;
  if (valueGetterFn) {
    gcd.valueGetter = (params) => {
      const row = tableModel.currentRows.get(params.id as string);
      if (row) {
        return valueGetterFn(row);
      }
    };
  }
  const renderCellFn = columnDef.renderCell;
  if (renderCellFn) {
    gcd.renderCell = (params) => {
      const row = tableModel.currentRows.get(params.id as string);
      if (row) {
        return renderCellFn(row);
      }
    };
  }
  gcd.field = columnDef.muiColDef.field;
  return gcd;
}

function createColumnDefFromCalculatedField(
  field: CalculatedField
): ColumnDef<any> | undefined {
  const fieldName = field.name;
  if (fieldName) {
    const label = field.label;
    const muiColumnDef: GridColDef = {
      field: fieldName,
      headerName: label,
      flex: 1,
    };
    return {
      hidden: false,
      muiColDef: muiColumnDef,
      valueGetter: field.valueGetterFn,
      renderCell: field.renderCellFn,
    };
  }
}

function createColumnDef(field: Field): ColumnDef<any> | undefined {
  const fieldName = field.name;
  if (fieldName) {
    const label = field.label;
    const muiColumnDef: GridColDef = {
      field: fieldName,
      headerName: label,
      flex: 1,
    };
    return {
      hidden: field.hidden ?? false,
      muiColDef: muiColumnDef,
      valueGetter: (row) => row[fieldName],
    };
  }
}

export interface BiqlTableModelArgs {
  biqlQuery: BiqlQuery;
  actions: RowAction<any>[];
}

export interface ChangeDataCaptureArgs<RowIdT> {
  database: string;
  table: string;
  extraMatchers?: ChangeDataCaptureMatcher<any, RowIdT>[];
}

export interface ChangeDataCaptureMatcher<MatcherRowT, RowIdT> {
  table: CdcTable;
  listener: (
    tableModel: TableModel<any>,
    cdcEvent: ChangeDataCaptureEvent,
    row: MatcherRowT
  ) => Array<RowIdT>;
}

export function createTableModel(args: BiqlTableModelArgs): TableModel<any> {
  const subject = new Subject<RowChange<any>>();

  QubesAPI.get()
    .biqlQuery<any>(
      args.biqlQuery.buildQueryString(),
      args.biqlQuery.appSpace(),
      "verbose"
    )
    .then((response) => {
      response.data.forEach((row) => {
        subject.next({ type: "upsert", row: row });
      });
    });

  const columns0 = new Array<ColumnDef<any>>();
  args.biqlQuery.fields().forEach((field) => {
    const cd = createColumnDef(field);
    if (cd) {
      columns0.push(cd);
    }
  });

  args.biqlQuery.calculatedFields().forEach((field) => {
    const cd = createColumnDefFromCalculatedField(field);
    if (cd) {
      columns0.push(cd);
    }
  });

  const tableModel: TableModel<any> = {
    columns: columns0,
    initialRows: [],
    rowId: (row) => row["uid"],
    realtime: subject,
    actions: args.actions,
    currentRows: new Map<RowIdT, any>(),
  };

  const cdc = args.biqlQuery.changeDataCaptureArgs();
  if (cdc) {
    const fetchRows = async function (
      uids: Array<string>
    ): Promise<Array<any>> {
      const response = await QubesAPI.get().biqlQuery(
        args.biqlQuery
          .clone()
          .appendWhere(
            "uid in (" + uids.map((uid) => '"' + uid + '"').join(",") + ")"
          )
          .buildQueryString(),
        args.biqlQuery.appSpace(),
        "verbose"
      );
      return response.data;
    };

    const matchers: Array<CdcTable> = [cdc.table];

    const cdcListenersByTable = new Map<
      string,
      ChangeDataCaptureMatcher<any, string>
    >();

    args.biqlQuery.fields().forEach((field) => {
      if (field.foreignCdc && field.name) {
        const fieldNameC = field.name;
        const fcdc = field.foreignCdc;
        const table = {
          database: cdc.table.database,
          table: fcdc.table,
        };
        matchers.push(table);

        const listener = function (
          tableModel: TableModel<any>,
          cdcEvent: ChangeDataCaptureEvent,
          row: any
        ): Array<string> {
          const foreignRowId = row[fcdc.pk];
          const rowIds = new Array<string>();
          tableModel.currentRows.forEach((v, k) => {
            if (v[fieldNameC] === foreignRowId) {
              rowIds.push(tableModel.rowId(v));
            }
          });
          return rowIds;
        };

        cdcListenersByTable.set(fcdc.table, {
          table: table,
          listener: listener,
        });
      }
    });

    const cdcListener = async function (
      changeEvent: ChangeDataCaptureEvent,
      row: any
    ) {
      console.log("ChangeDataCaptureEvent", changeEvent);
      let rowIds: Array<string> = [];
      if (changeEvent.table === cdc.table.table) {
        const rowId: string = row["uid"];
        rowIds.push(rowId);
      } else {
        const em = cdcListenersByTable.get(changeEvent.table);
        const rowIds0 = em?.listener(tableModel, changeEvent, row);
        if (rowIds0) {
          rowIds = rowIds0;
        }
      }
      if (rowIds.length > 0) {
        const resolvedRows = await fetchRows(rowIds);
        if (resolvedRows) {
          resolvedRows.forEach((row) => {
            tableModel.realtime?.next({ type: "upsert", row: row });
          });
          rowIds.forEach((rowId) => {
            let exists = false;
            resolvedRows.forEach((row) => {
              if (tableModel.rowId(row) === rowId) {
                exists = true;
              }
            });
            if (!exists) {
              tableModel.realtime?.next({ type: "delete", rowId: rowId });
            }
          });
        }
      }
    };

    GlobalClient.get().cdcSubscribe<any>(
      {
        tables: matchers,
        startSeq: "new",
      },
      cdcListener
    );
  }

  return tableModel;
}
