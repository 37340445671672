import { isEmpty } from "lodash";
import { FormBuilderOptions, FormItem } from "./form-builder.model";

export const defaultRequiredValidationFcn = (
  currentVal: unknown,
  formItem: FormItem
) => {
  var valid = true;
  var message = undefined;
  if (isEmpty(currentVal)) {
    valid = false;
    message = `${formItem.label ?? formItem.name} is a required field`;
  }
  return { valid, message };
};

export const validateFormItem = (
  fi: FormItem,
  formValues: Record<string, unknown>,
  formBuilderOpts: FormBuilderOptions
) => {
  if (!isEmpty(fi.validators) || fi.required) {
    const validators = [
      ...(fi.validators ?? []),
      ...(fi.required ? [defaultRequiredValidationFcn] : []),
    ];
    const fiErrors = validators
      .map((validator) => {
        return validator(formValues[fi.name], fi, formValues, formBuilderOpts);
      })
      .filter((validationResult) => !validationResult.valid);

    return fiErrors;
  } else return undefined;
};
