import {
  FormBuilderOptions,
  FormItem,
  ValidationResult,
} from "./form-builder.model";
import { FormInput } from "./form-input";
import "./form-builder.scss";
import { useCallback, useMemo, useState } from "react";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
} from "@elastic/eui";
import { LayoutService } from "./layout/layout-service";
import { DefaultFormLayout } from "./layout/default-form-layout";
import { isEmpty } from "lodash";
import { validateFormItem } from "./helper";

export type FormBuilderProps = {
  formBuilderOpts: FormBuilderOptions;
};

export const FormBuilder: React.FC<FormBuilderProps> = (props) => {
  const [values, setValues] = useState<Record<string, unknown>>(
    (): Record<string, unknown> =>
      props.formBuilderOpts.formItems.reduce(
        (vals: Record<string, unknown>, fi: FormItem) => {
          if (fi.value) vals[`${fi.name}`] = fi.value;
          return vals;
        },
        {} as Record<string, unknown>
      )
  );
  const [inputErrors, setInputErrors] = useState<
    Record<string, ValidationResult[] | undefined>
  >({});

  const validateAndSubmit = useCallback(
    (submitIfNoErrors: boolean) => {
      const errors: Record<string, ValidationResult[] | undefined> = {};
      props.formBuilderOpts.formItems.forEach((fi) => {
        errors[fi.name] = validateFormItem(fi, values, props.formBuilderOpts);
      });

      setInputErrors(errors);

      if (
        isEmpty(errors) &&
        submitIfNoErrors &&
        props.formBuilderOpts.onSubmit
      ) {
        props.formBuilderOpts.onSubmit(values);
      }
    },
    [props.formBuilderOpts, values]
  );

  /**
   * Preprocess the form builder options to apply any automatic goodness
   *
   * Currently just returns a copy of formBuilderOpts
   */
  const processedFormBuilderOpts: FormBuilderOptions = useMemo(() => {
    const originalFormBuilderOpts = props.formBuilderOpts;
    return {
      ...originalFormBuilderOpts,
    };
  }, [props.formBuilderOpts]);

  const layoutForm = (formBuilderOpts: FormBuilderOptions) => {
    const map: Record<string, JSX.Element> = formBuilderOpts.formItems.reduce(
      (labelToFormInputMap: Record<string, JSX.Element>, fi) => {
        labelToFormInputMap[fi.label ?? fi.name] = (
          <FormInput
            formLayout={
              formBuilderOpts.formLayoutPlugin ?? DefaultFormLayout.name
            }
            formItem={fi}
            formData={values}
            formErrors={inputErrors}
            setValue={(newValue) => {
              setValues((prevState) => {
                const newValues = {
                  ...prevState,
                  [fi.name]: newValue,
                };
                const errors = validateFormItem(fi, newValues, formBuilderOpts);
                setInputErrors((prevInputErrorState) => {
                  return {
                    ...prevInputErrorState,
                    [fi.name]: isEmpty(errors) ? undefined : errors,
                  };
                });

                return newValues;
              });
            }}
          />
        );
        return labelToFormInputMap;
      },
      {}
    );
    return LayoutService.get()
      .get(formBuilderOpts.formLayoutPlugin ?? DefaultFormLayout.name)
      ?.layoutForm(map);
  };

  return (
    <EuiPanel className="form-builder" grow={false}>
      <EuiFlexGroup direction="column" gutterSize="s">
        <EuiFlexItem>{layoutForm(processedFormBuilderOpts)}</EuiFlexItem>
        <EuiFlexGroup>
          <EuiButton onClick={() => validateAndSubmit(true)}>Submit</EuiButton>
          <EuiButton onClick={() => validateAndSubmit(false)}>
            Validate
          </EuiButton>
        </EuiFlexGroup>
      </EuiFlexGroup>
      {/* <EuiPanel
        className="currentValues"
        style={{ marginTop: "25px", backgroundColor: "lightblue" }}
      >
        <EuiText>Form Internal State Values</EuiText>
        {Object.keys(values).map((key) => {
          return (
            <div key={key}>
              {key} : {JSON.stringify(values[key])}
            </div>
          );
        })}
      </EuiPanel>
      <EuiPanel
        className="currentValues"
        style={{ marginTop: "25px", backgroundColor: "lightblue" }}
      >
        <EuiText>Form Internal State Errors</EuiText>
        {Object.keys(inputErrors).map((key) => {
          return (
            <div key={key}>
              {key} : {JSON.stringify(inputErrors[key])}
            </div>
          );
        })}
      </EuiPanel> */}
    </EuiPanel>
  );
};
