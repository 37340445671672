import React, { useCallback, useEffect, useRef, useState } from "react";
import { EuiPanel, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import TitleBar, { TitleBarProps } from "./title-bar";
import "./modal.scss";
import { ExpandableIconComponent } from "./expandable-icon";

export type tResizeCallback = {
  height: number;
  width: number;
};
export type tDragCallback = {
  left: number;
  top: number;
};

type ActionPanelTitleProps = {
  actionTitle?: string | JSX.Element;
};

export type ActionPanelProps = {
  panelHeader: TitleBarProps & ActionPanelTitleProps;
  panelBody: JSX.Element;
};

/**
 * @param scrollContent - Should the content (not the title bar) be scrollable
 */
export interface ModalProps extends TitleBarProps {
  id: string;
  panelId?: string;
  initialPosition?: {
    left: number | string;
    top: number | string;
  };
  initialSize?: {
    height: number | string;
    width: number | string;
  };
  backgroundColor?: string;
  dragCallback?: (position: tDragCallback) => void;
  resizeCallback?: (size: tResizeCallback, id?: string) => void;
  resizable?: boolean;
  children?: React.ReactElement | React.ReactElement[];
  style?: {};
  scrollContent?: boolean;
  actionPanelProps?: ActionPanelProps;
  bodyClassNames?: string[];
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  panelId,
  backgroundColor,
  initialPosition,
  initialSize,
  resizable,
  resizeCallback,
  dragCallback,
  children,
  style,
  scrollContent,
  actionPanelProps,
  bodyClassNames,
  ...titleProps
}) => {
  const isDragging = useRef(false);
  const mousePos = useRef({ x: 0, y: 0 });
  const componentSize = useRef({ height: 0, width: 0 });
  const componentPos = useRef({ left: 0, top: 0 });
  const elementRef = useRef<HTMLDivElement>(null);
  const selectedHandle = useRef<string>();
  const [zIndex] = useState<number>(1000);
  const [focusZIndex] = useState<number>();

  const handleViewableAreaResize = useCallback(() => {
    // reposition window if needed after parent container is resized, and then update the window manager
    repositionWithinBounds();
    if (dragCallback) {
      dragCallback({
        left: elementRef.current?.getBoundingClientRect().left ?? 100,
        top: elementRef.current?.getBoundingClientRect().top ?? 100,
      });
    }
  }, [dragCallback]);

  // if the window size changes, reposition windows as needed
  useEffect(() => {
    window.addEventListener("resize", handleViewableAreaResize);
    return () => {
      window.removeEventListener("resize", handleViewableAreaResize);
    };
    /**
     * Disabling eslint here because adding [handleZIndexIncrement] will cause the `handleZIndexIncrement`
     * callback to be re-rendered infinitely since the the useEffect would trigger the the useCallback to
     * update and vice versa
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleViewableAreaResize]);

  const hasFocus = zIndex === focusZIndex;

  /**
   * This function repositions the draggable window to remain within the viewable area.
   * It prioritizes enforcing the left boundary over the right, and top over bottom.
   */
  const repositionWithinBounds = () => {
    if (!elementRef.current) return;
    const requiredVisibleWidth = 120;
    const left = Number.parseInt(elementRef.current.style.left);
    const top = Number.parseInt(elementRef.current.style.top);
    const bottomBanner = document.getElementById("page-bottom");

    // keep width under window limits
    if (elementRef.current.getBoundingClientRect().width > window.innerWidth) {
      elementRef.current.style.width = `${window.innerWidth}px`;
    }

    const width = elementRef.current.getBoundingClientRect().width;

    if (left + width < requiredVisibleWidth) {
      // left
      elementRef.current.style.left = `${requiredVisibleWidth - width}px`;
    } else if (left > window.innerWidth - requiredVisibleWidth) {
      // right
      const newLeft = window.innerWidth - requiredVisibleWidth;
      elementRef.current.style.left = `${newLeft}px`;
    }

    const navBar = document.getElementById("page-header");
    const tOffset = navBar?.getBoundingClientRect().bottom
      ? navBar?.getBoundingClientRect().bottom
      : 0;

    if (tOffset >= top) {
      // top
      const newTop = tOffset;
      elementRef.current.style.top = `${newTop}px`;
    } else {
      // bottom
      const bOffset = bottomBanner?.getBoundingClientRect().top
        ? window.innerHeight - bottomBanner?.getBoundingClientRect().top + 30
        : 30;
      const bThreshold = window.innerHeight - bOffset;
      if (bThreshold <= top) {
        const newTop = window.innerHeight - bOffset;
        elementRef.current.style.top = `${newTop}px`;
      }
    }
  };

  // this is passed as a callback to the TitleBar component so we can handle mousedown on specific parts of the title bar rather than the whole thing
  const handleMouseDown = (e: React.MouseEvent) => {
    if (!elementRef.current) return;
    if (e.button === 0) {
      mousePos.current = { x: e.clientX, y: e.clientY };
      componentPos.current = {
        left: elementRef.current.getBoundingClientRect().left,
        top: elementRef.current.getBoundingClientRect().top,
      };
      isDragging.current = true;
      document.addEventListener("mousemove", handleDrag, false);
      document.addEventListener("mouseup", finishDrag, false);
      /**
       * Removed `e.preventDefault` because it was preventing the Baseball Card Toggle
       * View component from being removed from the DOM after losing focus
       *
       * Verified that removing `e.preventDefault` does not inhibit the normal
       * functionality of the `handleMouseDown` function
       */
    }
  };

  const handleDrag = (e: MouseEvent) => {
    if (!elementRef.current) return;
    if (isDragging.current) {
      document.documentElement.style.setProperty("cursor", "move");
      //   const newTop = mousePos.current.y;
      const newTop =
        elementRef.current.getBoundingClientRect().top -
        (mousePos.current.y - e.clientY);
      //   const newLeft = mousePos.current.x;
      const newLeft =
        elementRef.current.getBoundingClientRect().left -
        (mousePos.current.x - e.clientX);
      mousePos.current = { x: e.clientX, y: e.clientY };

      elementRef.current.style.transform = "";
      elementRef.current.style.top = `${newTop}px`;
      elementRef.current.style.left = `${newLeft}px`;
      repositionWithinBounds();

      e.stopPropagation();
      e.preventDefault();
    }
  };

  const finishDrag = () => {
    if (!elementRef.current) return;
    document.removeEventListener("mousemove", handleDrag, false);
    document.removeEventListener("mouseup", finishDrag, false);
    document.documentElement.style.setProperty("cursor", "auto");

    if (dragCallback) {
      dragCallback({
        left: elementRef.current.getBoundingClientRect().left,
        top: elementRef.current.getBoundingClientRect().top,
      });
    }
  };

  // handles initial handle click for resizing; adds listeners for mouse movement that handle resizing
  const onResizeClick = (e: React.MouseEvent, handler: string) => {
    if (!elementRef.current) return;
    selectedHandle.current = handler;
    componentPos.current = {
      left: elementRef.current.getBoundingClientRect().left,
      top: elementRef.current.getBoundingClientRect().top,
    };
    mousePos.current = { x: e.pageX, y: e.pageY };
    componentSize.current = {
      height: elementRef.current.offsetHeight,
      width: elementRef.current.offsetWidth,
    };
    document.addEventListener("mousemove", handleResize, false);
    document.addEventListener("mouseup", finishResize, false);
    e.preventDefault();
  };

  const finishResize = () => {
    if (!elementRef.current) return;
    document.removeEventListener("mousemove", handleResize, false);
    document.removeEventListener("mouseup", finishResize, false);
    document.documentElement.style.setProperty("cursor", "auto");

    repositionWithinBounds();
    if (dragCallback) {
      dragCallback({
        left: elementRef.current.getBoundingClientRect().left,
        top: elementRef.current.getBoundingClientRect().top,
      });
    }

    if (resizeCallback) {
      resizeCallback({
        height: elementRef.current.offsetHeight,
        width: elementRef.current.offsetWidth,
      });
    }
  };

  // handle resizing from north/top of element
  const resizeN = (e: MouseEvent) => {
    if (!elementRef.current) return;
    const endHeight =
      componentSize.current.height - (e.pageY - mousePos.current.y);
    const endTop = componentPos.current.top + (e.pageY - mousePos.current.y);
    elementRef.current.style.height = `${endHeight}px`;
    elementRef.current.style.top = `${endTop}px`;
  };

  // handle resizing from south/bottom of element
  const resizeS = (e: MouseEvent) => {
    if (!elementRef.current) return;
    const endHeight =
      componentSize.current.height + (e.pageY - mousePos.current.y);
    elementRef.current.style.height = `${endHeight}px`;
  };

  // handle resizing from east/right of element
  const resizeE = (e: MouseEvent) => {
    if (!elementRef.current) return;
    const endWidth =
      componentSize.current.width + (e.pageX - mousePos.current.x);
    elementRef.current.style.width = `${endWidth}px`;
  };

  // handle resizing from west/left of element
  const resizeW = (e: MouseEvent) => {
    if (!elementRef.current) return;
    const endWidth =
      componentSize.current.width - (e.pageX - mousePos.current.x);
    const endLeft = componentPos.current.left + (e.pageX - mousePos.current.x);
    elementRef.current.style.width = `${endWidth}px`;
    elementRef.current.style.left = `${endLeft}px`;
  };

  const handleResize = (e: MouseEvent) => {
    // calls resize function based on which handle is clicked
    // combines resize functions for intermediate directions
    switch (selectedHandle.current) {
      case "n":
        document.documentElement.style.setProperty("cursor", "ns-resize");
        resizeN(e);
        break;
      case "s":
        document.documentElement.style.setProperty("cursor", "ns-resize");
        resizeS(e);
        break;
      case "e":
        document.documentElement.style.setProperty("cursor", "ew-resize");
        resizeE(e);
        break;
      case "w":
        document.documentElement.style.setProperty("cursor", "ew-resize");
        resizeW(e);
        break;
      case "nw":
        document.documentElement.style.setProperty("cursor", "nwse-resize");
        resizeN(e);
        resizeW(e);
        break;
      case "ne":
        document.documentElement.style.setProperty("cursor", "nesw-resize");
        resizeN(e);
        resizeE(e);
        break;
      case "sw":
        document.documentElement.style.setProperty("cursor", "nesw-resize");
        resizeS(e);
        resizeW(e);
        break;
      case "se":
        document.documentElement.style.setProperty("cursor", "nwse-resize");
        resizeS(e);
        resizeE(e);
        break;
    }
  };

  /**
   * Returns the title bar within standard draggable window layout
   */
  const showStandardTitleBar = () => {
    const barColorProps = titleProps.barColor
      ? ""
      : { barColor: hasFocus ? "#2a2d35" : "#525761" };
    return (
      <EuiFlexItem grow={false}>
        <TitleBar
          onMouseDown={handleMouseDown}
          onClose={onClose}
          {...titleProps}
          {...barColorProps}
        />
      </EuiFlexItem>
    );
  };

  /**
   * Returns body within standard draggable window layout
   */
  const showStandardBody = (
    <EuiFlexItem
      grow={false}
      className={`draggable-body${scrollContent ? " with-scrolling" : ""}${
        bodyClassNames ? ` ${bodyClassNames.join(" ")}` : ""
      }`}
    >
      {children}
    </EuiFlexItem>
  );

  /** When actionPanelProps are passed in, this is the panel to the left of the the draggable window.  Title bar acts as the draggable div on mouse down but in use cases like the Workspace List window, the header content is display via the children of the standard body */
  const showLeftTitleBodyWithActionPanel = (
    <EuiFlexItem grow={false} className="content-left">
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem grow={false} className="workspace-window-left-title-bar">
          <TitleBar onMouseDown={handleMouseDown} {...titleProps} />
        </EuiFlexItem>
        {showStandardBody}
      </EuiFlexGroup>
    </EuiFlexItem>
  );

  /** When actionPanelProps are passed in, this is the panel to the right of the draggable window.  In the workspaces manager, contains the close button. */
  const showRightTitleBodyWithActionPanel = (
    <EuiFlexItem grow={false} className="content-right">
      <EuiFlexGroup
        direction="column"
        gutterSize="none"
        className="bl full-size"
      >
        <EuiFlexItem
          grow={false}
          className={`content-right__title-bar title-bar`}
        >
          <TitleBar
            onMouseDown={handleMouseDown}
            {...actionPanelProps?.panelHeader}
            id={actionPanelProps?.panelHeader.id ?? "default_id"}
          />
        </EuiFlexItem>
        <EuiFlexItem className="content-body br full-size">
          {actionPanelProps?.panelBody}
        </EuiFlexItem>
      </EuiFlexGroup>
      <ExpandableIconComponent />
    </EuiFlexItem>
  );

  const focusClassName = hasFocus ? "in-focus-shadow" : "";

  return (
    <EuiPanel
      paddingSize="none"
      hasBorder={false}
      hasShadow={false}
      className={`modal-panel modal-visibility ${focusClassName}`}
      id={panelId ?? "modal-panel"}
      style={{
        // background-color will not apply if added via class which is why they are included inline here
        backgroundColor: backgroundColor ?? "rgba(52, 55, 65, 0.95)",
        left: initialPosition?.left ?? "50%",
        top: initialPosition?.top ?? "50%",
        transform: `translate(-50%, -50%)`,
        height: initialSize?.height,
        width: initialSize?.width,
        zIndex: zIndex,
        ...style,
      }}
      panelRef={elementRef}
      //   onMouseDown={bringToFront}
    >
      <div className="resize-container resize-z-index">
        {resizable && (
          <>
            {/* horizontal/vertical resizing handles */}
            <div
              className="resize-v--n resize-z-index"
              onMouseDown={(e) => onResizeClick(e, "n")}
            />
            <div
              className="resize-v--s resize-z-index"
              onMouseDown={(e) => onResizeClick(e, "s")}
            />
            <div
              className="resize-h--e resize-z-index"
              onMouseDown={(e) => onResizeClick(e, "e")}
            />
            <div
              className="resize-h--w resize-z-index"
              onMouseDown={(e) => onResizeClick(e, "w")}
            />

            {/* corner resizing handles */}
            <div
              className="resize-corner--nw resize-z-index"
              onMouseDown={(e) => onResizeClick(e, "nw")}
            />
            <div
              className="resize-corner--ne resize-z-index"
              onMouseDown={(e) => onResizeClick(e, "ne")}
            />
            <div
              className="resize-corner--sw resize-z-index"
              onMouseDown={(e) => onResizeClick(e, "sw")}
            />
            <div
              className="resize-corner--se resize-z-index"
              onMouseDown={(e) => onResizeClick(e, "se")}
            />
          </>
        )}
        <EuiFlexGroup
          direction={actionPanelProps ? "row" : "column"}
          gutterSize="none"
          responsive={false}
          className={`resize-container-content${
            actionPanelProps ? " action-panel-window" : ""
          }`}
        >
          {!actionPanelProps && showStandardTitleBar()}
          {!actionPanelProps && showStandardBody}
          {actionPanelProps && showLeftTitleBodyWithActionPanel}
          {actionPanelProps && showRightTitleBodyWithActionPanel}
          {/* This only renders when the panel is whole */}
          {!actionPanelProps && resizable && <ExpandableIconComponent />}
        </EuiFlexGroup>
      </div>
    </EuiPanel>
  );
};

Modal.defaultProps = {
  resizable: true,
};

export default Modal;
