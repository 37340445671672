export class DurationUnit {
    constructor(public readonly value: number, public readonly name: string) {}
  }
  
  export const nanoSecond = new DurationUnit(1, 'ns');
  export const microSecond = new DurationUnit(1000 * nanoSecond.value, 'µs');
  export const milliSecond = new DurationUnit(1000 * microSecond.value, 'ms');
  export const second = new DurationUnit(1000 * milliSecond.value, 's');
  export const minute = new DurationUnit(60 * second.value, 'm');
  export const hour = new DurationUnit(60 * minute.value, 'h');
  export const day = new DurationUnit(24 * hour.value, 'd');
  
  export interface DurationObject {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }
  
  export class Duration {
    public readonly value: number;
    public readonly unit: DurationUnit;
  
    constructor(value: number, unit: DurationUnit = nanoSecond) {
      this.value = Math.round(value * unit.value) / unit.value;
      this.unit = unit;
    }
  
    to(unit: DurationUnit, isFloor: boolean = false) {
      const v = this.value ? Math.round(this.value * this.unit.value) / unit.value : 0;
      return isFloor ? Math.floor(v) : v;
    }
  
    add(v: number, unit?: DurationUnit): Duration;
    add(v: Duration): Duration;
    add(v: number | Duration, unit: DurationUnit = nanoSecond): Duration {
      if (typeof v === 'number') {
        v = new Duration(v, unit);
      }
      return new Duration(this.value + v.to(this.unit), this.unit);
    }
  
    sub(v: number, unit?: DurationUnit): Duration;
    sub(v: Duration): Duration;
    sub(v: number | Duration, unit: DurationUnit = nanoSecond) {
      if (typeof v === 'number') {
        v = new Duration(v, unit);
      }
      return new Duration(this.value - v.to(this.unit), this.unit);
    }
  
    toString() {
      return `${this.value}${this.unit.name}`;
    }
  
    toObject(): DurationObject {
      const ns = Math.round(this.to(nanoSecond));
  
      return {
        days: Math.floor(ns / day.value),
        hours: Math.floor(ns % day.value / hour.value),
        minutes: Math.floor(ns % day.value % hour.value / minute.value),
        seconds: ns % day.value % hour.value % minute.value / second.value,
      };
    }
  
    valueOf() {
      return this.to(nanoSecond);
    }
  
    static since(d: Date, current: Date = new Date()): Duration {
      return new Duration(current.getTime() - d.getTime(), milliSecond);
    }
  
    static until(d: Date, current: Date = new Date()): Duration {
      return new Duration(d.getTime() - current.getTime(), milliSecond);
    }
  
    static fromObject(obj: Partial<DurationObject>): Duration {
      return new Duration(obj.days || 0, day)
        .add(new Duration(obj.hours || 0, hour))
        .add(new Duration(obj.minutes || 0, minute))
        .add(new Duration(obj.seconds || 0, second));
    }
  }