export type FormLayout = {
  name: string;
  layoutForm: (formItems: Record<string, JSX.Element>) => JSX.Element;
  layoutFormItem: (
    formItemLayoutProperties: FormItemLayoutProperties
  ) => JSX.Element;
};

export type FormItemLayoutProperties = {
  label: string;
  input: JSX.Element;
  helperText?: string;
  errorText?: string;
};

export class LayoutService {
  static readonly _instance: LayoutService = new LayoutService();

  static get() {
    return this._instance;
  }

  private layouts = new Map<string, FormLayout>();

  register(layout: FormLayout) {
    this.layouts.set(layout.name, layout);
  }

  list() {
    return this.layouts.keys();
  }

  get(name: string): FormLayout | undefined {
    return this.layouts.get(name);
  }
}
