import { useEffect, useState } from "react";
import { Duration } from "../../duration";

export type CellRendererProps<T> = {
  value: T | undefined;
};

/**
 * Cell Renderer that shows you the uptime
 */
export const UptimeRenderer: React.FC<CellRendererProps<Date>> = ({
  value,
}) => {

  const [uptime, setUptime] = useState<string>(
    value ? durationString(value) : ""
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setUptime(value ? durationString(value) : "");
    }, 1000);

    return () => clearInterval(timer);
  }, [setUptime, value]);

  return <>{uptime}</>;
};


function durationString(startDate: Date): string {
  const d = Duration.since(startDate).toObject();
  // return d.toString()
  const parts = [];

  if (d.days == 1) {
    parts.push(`${d.days} day`);
  }
  if (d.days > 1) {
    parts.push(`${d.days} day`);
  }

  function pad2(n: number): string {
    if (n < 10) {
      return "0" + n;
    } else {
      return "" + n;
    }
  }

  parts.push(pad2(d.hours) + ":" + pad2(d.minutes) + ":" + pad2(Math.round(d.seconds)));

  return parts.join(" ");

}