import {
  EuiBottomBar,
  EuiButton,
  EuiHeader,
  EuiPageHeader,
  EuiPageSection,
  EuiText,
  EuiTextArea,
} from "@elastic/eui";
import "./playground.scss";
import { useEffect, useMemo, useState } from "react";
import { BiqlQueryResponse } from "../../../qubes-client/protocol/biql-query.protocol";
import { QubesAPI } from "../../../qubes-client/api";

const Playground: React.FC = () => {
  const pageContent = <div className="contents">contents</div>;

  const [biqlQuery, setBiqlQuery] = useState<string>();
  const [response, setResponse] = useState<BiqlQueryResponse<any>>();

  const submitQuery = async () => {
    if (!biqlQuery) return;
    const result = await QubesAPI.get().biqlQuery<any>(
      // `from store
      //   select ${POSStoreFieldList.join()}
      //   where active = true`,
      biqlQuery,
      "nefario",
      "verbose"
    );
    setResponse(result);
  };

  useEffect(() => {}, [biqlQuery]);

  const fallbackComponent = useMemo(() => {
    return (
      <EuiText>
        An error was encountered. See Development Tools console for details
      </EuiText>
    );
  }, []);

  return (
    <div className="_playground_page">
      <EuiHeader theme="dark" position="fixed" className="header">
        <EuiText>this is the header</EuiText>
      </EuiHeader>
      <EuiHeader position="fixed">
        <EuiText>this is the menu</EuiText>
      </EuiHeader>
      <EuiPageHeader pageTitle="This is the page title"></EuiPageHeader>
      <EuiPageSection restrictWidth={1000}>
        <EuiTextArea
          fullWidth={true}
          placeholder="Enter biql query here"
          onChange={(evt) => setBiqlQuery(evt.target.value)}
        ></EuiTextArea>
        <EuiButton onClick={submitQuery}>Submit</EuiButton>
      </EuiPageSection>
      <EuiPageSection>
        {/*response ? (               // ??? TODO this needs re-work for mui support
          <ErrorBoundary fallback={fallbackComponent}>
            <QubesGrid
              biqlQuery={biqlQuery!}
              metadata={response.metadata}
              data={response.data}
              view={undefined as any}
            />
          </ErrorBoundary>
        ) : // <QubesTable biqlQuery={biqlQuery!} response={response} />
        null*/}
      </EuiPageSection>
      <EuiBottomBar paddingSize="s">this is the footer</EuiBottomBar>
    </div>
  );
};

export default Playground;
