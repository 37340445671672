import { useMemo } from "react";
import "./hermes-devtools-page.scss";
import "@elastic/eui/dist/eui_theme_light.css";
import { useParams } from "react-router-dom";
import { TableModelView } from "../../model/qubes/qubes-model";
import { Subject } from "rxjs";
import {
  RowChange,
  TableModel,
} from "../../components/qubes-table/table-model";
import GlobalClient, { RpcRequestResponse } from "../../hermesclient/client";
import QubesPage from "../qubes/page/page";

const viewsByReaderKey = new Map<string, TableModelView>();

export const HermesDevtoolsPage: React.FC = () => {
  const { readerKey } = useParams();

  const rpcView = useMemo(() => {
    const rrk = readerKey ?? "";
    let view = viewsByReaderKey.get(rrk);
    if (view === undefined) {
      view = RpcRequestResponseView(rrk);
      viewsByReaderKey.set(rrk, view);
    }
    return view;
  }, [readerKey]);

  return <QubesPage views={[rpcView]} />;
  // <EuiProvider>
  //   <div className="_hermes_devtools_page_">
  //     <h2>Mailbox Reader Key {readerKey ?? "not specified"}</h2>
  //     <div className="devtools-table">
  //       <QubesPage
  //         views={[rpcView]}
  //       />
  //     </div>
  //   </div>
  // </EuiProvider>
};
export function formatTimestamp(date?: Date): string {
  if (date === undefined) {
    return "";
  }

  function pad2(n: number): string {
    if (n < 10) {
      return "0" + n;
    } else {
      return "" + n;
    }
  }

  function pad3(n: number): string {
    if (n >= 100) {
      return "" + n;
    } else if (n > 10) {
      return "0" + n;
    } else {
      return "00" + n;
    }
  }

  const s =
    date.getFullYear() +
    "-" +
    pad2(date.getMonth() + 1) +
    "-" +
    pad2(date.getDate()) +
    " " +
    pad2(date.getHours()) +
    ":" +
    pad2(date.getMinutes()) +
    ":" +
    pad2(date.getSeconds()) +
    "." +
    pad3(date.getMilliseconds());
  return s;
}

export function RpcRequestResponseView(readerKey: string): TableModelView {
  console.log("RpcRequestResponseView", readerKey);

  const subject = new Subject<RowChange<any>>();

  function listener(correlation: RpcRequestResponse): void {
    subject.next({ type: "upsert", row: correlation });
  }
  GlobalClient.get().rpcObserverSubscribe(readerKey, listener);

  const currentRows = new Map<string, RpcRequestResponse>();

  const tableModel: TableModel<RpcRequestResponse> = {
    columns: [
      {
        muiColDef: {
          field: "correlationId",
          headerName: "Correlation Id",
          width: 250,
        },
        hidden: true,
        valueGetter: (row) => row.correlationId,
      },
      {
        muiColDef: {
          field: "endPoint()",
          headerName: "Endpoint",
          width: 225,
        },
        valueGetter: (row) => row.endPoint(),
      },
      {
        muiColDef: {
          field: "Role",
          headerName: "Role",
          width: 75,
        },
        valueGetter: (row) => row.role(),
      },
      {
        muiColDef: {
          field: "RemoteMailbox",
          headerName: "Remote Mailbox",
          width: 250,
        },
        valueGetter: (row) => {
          if (row.isClient()) {
            return row.sendReceipt?.request?.to?.join(", ") ?? "";
          } else {
            return row.inboxMessage?.header?.sender ?? "";
          }
        },
      },
      {
        muiColDef: {
          field: "Started",
          headerName: "Started",
          width: 175,
        },
        valueGetter: (row) => formatTimestamp(row.timeStarted()),
      },
      {
        muiColDef: {
          field: "Completed",
          headerName: "Completed",
          width: 175,
        },
        valueGetter: (row) => formatTimestamp(row.timeCompleted()),
      },
      {
        muiColDef: {
          field: "Status",
          headerName: "Status",
          width: 75,
        },
        valueGetter: (row) => row.status(),
      },
      {
        muiColDef: {
          field: "ContentType",
          headerName: "Content Type",
          width: 75,
        },
        valueGetter: (row) => {
          if (row.isJson()) {
            return "json";
          } else if (row.isProtobuf()) {
            return "protobuf";
          } else {
            return "" + row.contentType;
          }
        },
      },
      {
        muiColDef: {
          field: "Duration",
          headerName: "Duration",
          width: 75,
        },
        valueGetter: (row) => {
          const d = row?.durationInMillis();
          if (d !== undefined) {
            return "" + d + "ms";
          } else {
            return "";
          }
        },
      },
      {
        muiColDef: {
          field: "ResponseSize",
          headerName: "Response Size",
          width: 75,
        },
        valueGetter: (row) => row?.responseEnvelope()?.messageBytes?.length,
      },
      {
        muiColDef: {
          field: "RequestSize",
          headerName: "Request Size",
          width: 75,
        },
        valueGetter: (row) => row?.requestEnvelope()?.messageBytes?.length,
      },
    ],
    initialRows: [],
    currentRows: currentRows,
    rowId(row: RpcRequestResponse): string {
      return row.correlationId;
    },
    realtime: subject,
    actions: [
      {
        name: "Show Request",
        icon: "Replay",
        run: async function (actionCtx) {
          const ro = await actionCtx.row.requestObj();
          actionCtx.showModalFn(ro);
        },
      },
      {
        name: "Show Response",
        icon: "Replay",
        run: async function (actionCtx) {
          const ro = await actionCtx.row.responseObj();
          actionCtx.showModalFn(ro);
        },
      },
    ],
  };

  return {
    kind: "tableModel",
    name: "RPC Requests for " + readerKey,
    tableModel: tableModel,
  };
}
