import { useMemo } from "react";
import { FormItem, ValidationResult } from "./form-builder.model";
import { TextInput } from "./input/text-input";
import { NumberInput } from "./input/number-input";
import { PasswordInput } from "./input/password-input";
import { LayoutService } from "./layout/layout-service";
import { SelectInput } from "./input/select-input";
import { TextAreaInput } from "./input/textarea-input";
import { CheckboxInput } from "./input/checkbox-input";
import { ToggleInput } from "./input/toggle-input";
import { ComboBoxInput } from "./input/combobox-input";
import { isEmpty } from "lodash";

export type FormInputProps = {
  formLayout: string;
  formItem: FormItem;
  formData: Record<string, unknown>;
  formErrors: Record<string, ValidationResult[] | undefined>;
  setValue: (newValue: unknown) => void;
};

export const FormInput: React.FC<FormInputProps> = ({
  formLayout,
  formItem,
  formData,
  formErrors,
  setValue,
}) => {
  const formInput = useMemo(() => {
    switch (formItem.type) {
      case "text":
        return (
          <TextInput
            formItem={formItem}
            formData={formData}
            formErrors={formErrors}
            setValue={setValue}
          />
        );
      case "textarea":
        return (
          <TextAreaInput
            formItem={formItem}
            formData={formData}
            formErrors={formErrors}
            setValue={setValue}
          />
        );
      case "number":
        return (
          <NumberInput
            formItem={formItem}
            formData={formData}
            formErrors={formErrors}
            setValue={setValue}
          />
        );
      case "password":
        return (
          <PasswordInput
            formItem={formItem}
            formData={formData}
            formErrors={formErrors}
            setValue={setValue}
          />
        );
      case "checkbox":
        return (
          <CheckboxInput
            formItem={formItem}
            formData={formData}
            formErrors={formErrors}
            setValue={setValue}
          />
        );
      case "toggle":
        return (
          <ToggleInput
            formItem={formItem}
            formData={formData}
            formErrors={formErrors}
            setValue={setValue}
          />
        );
      case "select":
        return (
          <SelectInput
            formItem={formItem}
            formData={formData}
            formErrors={formErrors}
            setValue={setValue}
            options={
              typeof formItem.options === "function"
                ? formItem.options()
                : formItem.options
            }
          />
        );
      case "combobox":
        return (
          <ComboBoxInput
            formItem={formItem}
            formData={formData}
            formErrors={formErrors}
            setValue={setValue}
            options={
              typeof formItem.options === "function"
                ? formItem.options()
                : formItem.options
            }
          />
        );
      default:
        console.error(
          `Don't know how to handle form input type ${(formItem as any).type}`
        );
    }
  }, [formItem, formData, formErrors, setValue]);

  const errorText = useMemo(() => {
    const formItemErrors = formErrors[formItem.name];
    if (
      !formItemErrors ||
      isEmpty(formItemErrors) ||
      formItemErrors.every((validationResult) => validationResult.valid)
    )
      return undefined;
    return formItemErrors
      .filter((validationResult) => !validationResult.valid)
      .map((validationResult) => validationResult.message)
      .join("\n");
  }, [formItem.name, formErrors]);

  return (
    <div
      className="form-input"
      key={formItem.name}
      id={`form-input-${formItem.name}`}
    >
      {LayoutService.get()
        .get(formLayout)
        ?.layoutFormItem({
          label: formItem.label ?? formItem.name,
          input: formInput!,
          errorText: errorText,
        })}
    </div>
  );
};
