import { EuiFieldPassword } from "@elastic/eui";
import { FormInputProps } from "../form-input";

export type PasswordInputProps = {} & Omit<FormInputProps, "formLayout">;

export const PasswordInput: React.FC<PasswordInputProps> = ({
  formItem,
  formData,
  setValue,
}) => {
  const resolvedValue = (): string => {
    const e = formData[formItem.name];
    if (!e) return "";
    else return e as string;
  };
  return (
    <div className="password-input">
      <EuiFieldPassword
        id={formItem.name}
        value={resolvedValue()}
        onChange={(evt) => setValue(evt.target.value)}
      />
    </div>
  );
};
