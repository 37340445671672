import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiToolTip } from "@elastic/eui";
import TitleBarActions from "./title-bar-actions";

export interface TitleBarProps {
  id: string;
  title?: string | JSX.Element;
  titleLogo?: string;
  barColor?: string;
  titleColor?: string;
  noTooltip?: boolean;
  actions?: {
    name: string;
    icon: string;
    tooltip: string;
    actionFunction: (props?: any) => void;
    disabled?: boolean;
  }[];
  noAction?: boolean;
  actionComponent?: JSX.Element;
  openFeedbackFrom?: string;
  onMouseDown?: (e: React.MouseEvent) => void;
  onClose?: (props?: any) => void;
}

const TitleBar: React.FC<TitleBarProps> = (props) => {
  const {
    title,
    barColor,
    titleColor,
    noAction = false,
    actionComponent,
    noTooltip = false,
    onMouseDown,
    onClose,
  } = props;
  const closeWindow = () => {
    onClose && onClose();
  };

  return (
    <EuiFlexItem
      className={`title-bar${!barColor && title ? " bb" : ""}`}
      style={{
        backgroundColor: barColor ?? "rgba(0,0,0,0)",
      }}
    >
      <EuiFlexGroup
        direction="row"
        alignItems="center"
        justifyContent="spaceBetween"
        gutterSize="none"
        className="full-size"
        responsive={false}
      >
        <EuiFlexItem
          className="title-text-container"
          onMouseDown={onMouseDown}
          style={{
            minHeight: title === undefined ? "50px" : undefined,
          }}
        >
          <EuiFlexGroup gutterSize="none" alignItems="center">
            {actionComponent && (
              <EuiFlexItem grow={false} className="left-title-action">
                {actionComponent}
              </EuiFlexItem>
            )}
            <EuiFlexItem className="title-bar__title-item">
              {noTooltip ? (
                <span style={{ color: titleColor ?? "#FFF" }}>{title}</span>
              ) : (
                <EuiToolTip
                  content={title}
                  className="eui-title-bar-tooltip"
                  position="bottom"
                >
                  <span style={{ color: titleColor ?? "#FFF" }}>{title}</span>
                </EuiToolTip>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {!noAction && (
          <TitleBarActions onWindowClose={closeWindow} {...props} />
        )}
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};

export default TitleBar;
