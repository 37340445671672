import React from "react";
import { memoize } from "../../Utils";
import { showForm } from "../../components/forms/form-builder-manager";
import {
  FormBuilderOptions,
  FormItem,
} from "../../components/forms/form-builder.model";
import { ActionCtx } from "../../components/qubes-table/table-model";
import GlobalClient from "../../hermesclient/client";
import { ContentType } from "../../hermesclient/proto/wsmessages";
import { BiqlView } from "../../model/qubes/qubes-model";
import { BiqlQuery } from "../../qubes-client/api";
import { UptimeRenderer } from "../../components/cell-renderers/uptime.cell-renderer";

export const defaultViews = memoize(() => [activeServicesView()]);

const activeServicesBQ: BiqlQuery = BiqlQuery()
  .from("nefario", "service")
  .changeDataCapture("nefario", "service", "uid")
  .field({ name: "uid", hidden: true, primaryKey: true })
  .field({
    name: "minionUid",
    hidden: true,
    foreignCdc: { table: "minion", pk: "uid" },
  })
  .field({
    name: "minion.serverUid",
    hidden: true,
    foreignCdc: { table: "server", pk: "uid" },
  })
  .field({ name: "minion.label", label: "Minion" })
  .field("name")
  .field({ name: "mostRecentProcessRunUid", hidden: true })
  .field({ name: "ProcessRun.startedAt", label: "Started At" })
  .field({ name: "MinionProcess.uid", hidden: true })
  .field({ name: "Mailbox", hidden: true })
  .field("mostRecentProcessRun.status")
  .field({ name: "systemdUnitJson", hidden: true })
  .calculatedField({
    name: "UpTime",
    label: "Up Time",
    // valueGetterFn: row => row,
    renderCellFn: (row) => {
      const startedAt = row["ProcessRun.startedAt"];
      const status = row["mostRecentProcessRun.status"];
      //if we're not running, we don't really want to show anything
      if (status === "running") {
        const resolvedValue = startedAt ? new Date(startedAt + "Z") : undefined;
        return <UptimeRenderer value={resolvedValue} />;
      } else {
        return <></>;
      }
    },
  })
  .where("minionEnabled");

function showLogs(actionCtx: ActionCtx<any>) {
  const action = "download"; // or "tail"
  const row = actionCtx.row;
  const processRunUid = row.mostRecentProcessRunUid;
  const url = `https://hermes-go.ahsrcm.com/api/stream_${action}/${processRunUid}/console`;
  window.open(url, "_blank")?.focus();
}

async function runServiceAction(
  actionCtx: ActionCtx<any>,
  serviceAction: string,
  commandOutputIsJson: boolean
) {
  const row = actionCtx.row;
  // const tableModel = actionCtx.tableModel;
  // const rowId = tableModel.rowId(row);

  const mailbox = row["Mailbox"];
  const encoder = new TextEncoder();

  const response = await GlobalClient.get().rawRpcCall({
    endPoint: "SystemdServiceAction",
    to: mailbox,
    contentType: ContentType.Json,
    body: encoder.encode(
      JSON.stringify({
        serviceName: row["name"],
        action: serviceAction,
      })
    ),
  });

  // const frameType = response.header?.rpcHeader?.frameType;

  console.log("Response", response);

  let responseBody: any = "";
  // let title = "";

  if (response.data) {
    const responseJson = new TextDecoder().decode(response.data);
    try {
      responseBody = JSON.parse(responseJson);
      console.log("Response body", responseBody);
    } catch (e) {
      console.log("Error parsing json response", responseJson, e);
    }
    // if (commandOutputIsJson && frameType === RpcFrameType.SuccessResponse) {
    //   const commandResults = JSON.parse(responseBody.commandOutput);
    //   console.log("Command success results", commandResults);
    // }
  }

  // Currently unused but saving for later possible use
  // switch (frameType) {
  //   case RpcFrameType.SuccessResponse:
  //     title = "Success";
  //     break;
  //   case RpcFrameType.ErrorResponse:
  //     title = "Fail";
  //     break;
  //   default:
  //     title = `Unexpected frame type ${frameType}`;
  //     break;
  // }

  actionCtx.showModalFn(responseBody);
}

export function activeServicesView(): BiqlView {
  return {
    kind: "biql",
    name: "Active Services",
    biqlQuery: activeServicesBQ,
    rowActions: [
      {
        name: "Status",
        icon: "Replay",
        run: function (ctx) {
          runServiceAction(ctx, "Status", true);
        },
      },
      {
        name: "Start",
        icon: "Replay",
        run: function (ctx) {
          runServiceAction(ctx, "Start", false);
        },
      },
      {
        name: "Stop",
        icon: "Replay",
        run: function (ctx) {
          runServiceAction(ctx, "Stop", false);
        },
      },
      {
        name: "Restart",
        icon: "Replay",
        run: function (ctx) {
          runServiceAction(ctx, "Restart", false);
        },
      },
      {
        name: "View Config",
        icon: "Replay",
        run: function (ctx) {
          runServiceAction(ctx, "Cat", false);
        },
      },
      {
        name: "Show Logs",
        icon: "Replay",
        run: showLogs,
      },
      {
        name: "Edit",
        icon: "Replay",
        run: function (ctx) {
          const formItems: FormItem[] = Object.keys(ctx.row)
            .filter((key) => !key.includes("."))
            .map((key) => {
              let typeofValue: "text" | "number" | "textarea";
              switch (typeof ctx.row[key]) {
                case "string":
                  typeofValue = "text";
                  break;
                case "bigint":
                case "number":
                  typeofValue = "number";
                  break;
                case "object":
                  typeofValue = "textarea";
                  break;
                default:
                  throw new Error(
                    `Don't know how to handle type ${typeof ctx.row[key]}`
                  );
              }

              return {
                name: key,
                type: typeofValue,
                value: ctx.row[key],
              };
            });
          const formBuilderOpts: FormBuilderOptions = {
            formItems,
          };
          showForm.next(formBuilderOpts);
        },
      },
    ],
  };
}
