/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Struct, Value } from "../google/protobuf/struct";
import Long from "long";


export const protobufPackage = "hermes";

export enum RpcFrameType {
  UnspecifiedRFT = 0,
  Request = 1,
  SuccessResponse = 2,
  ErrorResponse = 3,
  UNRECOGNIZED = -1,
}

export function rpcFrameTypeFromJSON(object: any): RpcFrameType {
  switch (object) {
    case 0:
    case "UnspecifiedRFT":
      return RpcFrameType.UnspecifiedRFT;
    case 1:
    case "Request":
      return RpcFrameType.Request;
    case 2:
    case "SuccessResponse":
      return RpcFrameType.SuccessResponse;
    case 3:
    case "ErrorResponse":
      return RpcFrameType.ErrorResponse;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RpcFrameType.UNRECOGNIZED;
  }
}

export function rpcFrameTypeToJSON(object: RpcFrameType): string {
  switch (object) {
    case RpcFrameType.UnspecifiedRFT:
      return "UnspecifiedRFT";
    case RpcFrameType.Request:
      return "Request";
    case RpcFrameType.SuccessResponse:
      return "SuccessResponse";
    case RpcFrameType.ErrorResponse:
      return "ErrorResponse";
    case RpcFrameType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ContentType {
  UnspecifiedCT = 0,
  Protobuf = 1,
  Json = 2,
  Binary = 3,
  Text = 4,
  UNRECOGNIZED = -1,
}

export function contentTypeFromJSON(object: any): ContentType {
  switch (object) {
    case 0:
    case "UnspecifiedCT":
      return ContentType.UnspecifiedCT;
    case 1:
    case "Protobuf":
      return ContentType.Protobuf;
    case 2:
    case "Json":
      return ContentType.Json;
    case 3:
    case "Binary":
      return ContentType.Binary;
    case 4:
    case "Text":
      return ContentType.Text;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ContentType.UNRECOGNIZED;
  }
}

export function contentTypeToJSON(object: ContentType): string {
  switch (object) {
    case ContentType.UnspecifiedCT:
      return "UnspecifiedCT";
    case ContentType.Protobuf:
      return "Protobuf";
    case ContentType.Json:
      return "Json";
    case ContentType.Binary:
      return "Binary";
    case ContentType.Text:
      return "Text";
    case ContentType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MessageFromClient {
  sendMessageRequest?: SendMessageRequest | undefined;
  firstMessage?: FirstMessage | undefined;
  ping?: Ping | undefined;
  pong?: Pong | undefined;
  notification?: Notification | undefined;
  subscribeRequest?: SubscribeRequest | undefined;
}

export interface Notification {
  message?: string | undefined;
}

export interface MessageToClient {
  messageEnvelope?: MessageEnvelope | undefined;
  sendMessageResponse?: SendMessageResponse | undefined;
  ping?: Ping | undefined;
  pong?: Pong | undefined;
  notification?: Notification | undefined;
  subscribeResponse?: SubscribeResponse | undefined;
}

export interface Ping {
  payload?: Uint8Array | undefined;
}

export interface Pong {
  payload?: Uint8Array | undefined;
}

export interface MessageHeader {
  sender?: string | undefined;
  contentType?: ContentType | undefined;
  rpcHeader?: RpcHeader | undefined;
  senderSequence?: number | undefined;
  extraHeaders?: KeyValPair[] | undefined;
}

export interface SenderEnvelope {
  created?: number | undefined;
}

export interface ServerEnvelope {
  sequence?: number | undefined;
  created?: number | undefined;
  channel?: string | undefined;
  subscriptionId?: string | undefined;
}

export interface KeyValPair {
  key?: string | undefined;
  val?: string | undefined;
}

export interface RpcHeader {
  correlationId?: string | undefined;
  endPoint?: string | undefined;
  frameType?: RpcFrameType | undefined;
  errorInfo?: RpcErrorInfo | undefined;
}

export interface RpcErrorInfo {
  errorCode?: number | undefined;
  message?: string | undefined;
  stackTrace?: string | undefined;
}

export interface Message {
  header?: MessageHeader | undefined;
  senderEnvelope?: SenderEnvelope | undefined;
  serverEnvelope?: ServerEnvelope | undefined;
  data?: Uint8Array | undefined;
}

export interface MessageEnvelope {
  messageBytes?: Uint8Array | undefined;
  serverEnvelope?: ServerEnvelope | undefined;
}

export interface SendMessageRequest {
  to?: string[] | undefined;
  message?: Message | undefined;
  channel?: string | undefined;
  idempotentId?: string | undefined;
}

export interface SendMessageResponse {
  errors?: SendMessageError[] | undefined;
  duplicates?: string[] | undefined;
  idempotentId?: string | undefined;
  correlationId?: string | undefined;
}

export interface SendReceipt {
  request?: SendMessageRequest | undefined;
  response?: SendMessageResponse | undefined;
  serverEnvelope?: ServerEnvelope | undefined;
}

export interface SendMessageError {
  message?: string | undefined;
  to?: string | undefined;
}

export interface FirstMessage {
  senderInfo?: SenderInfo | undefined;
  mailboxTimeoutInMs?: number | undefined;
  subscriptions?: Subscription[] | undefined;
}

export interface SenderInfo {
  readerKey?: string | undefined;
  address?: string | undefined;
}

export interface SubscribeRequest {
  subscriptions?: Subscription[] | undefined;
}

export interface SubscribeResponse {
  succeeded?: string[] | undefined;
  errors?: SubscribeError[] | undefined;
}

export interface SubscribeError {
  state?: string | undefined;
  message?: string | undefined;
}

export interface Subscription {
  mailbox?: MailboxSubscription | undefined;
  nefario?: NefarioSubscription | undefined;
  changeDataCapture?: ChangeDataCaptureSubscription | undefined;
  unsubscribe?: Unsubscribe | undefined;
}

export interface MailboxSubscription {
  id?: string | undefined;
  state?: string | undefined;
  readerKey?: string | undefined;
  channel?: string | undefined;
  startSeq?: string | undefined;
}

export interface ChangeDataCaptureSubscription {
  id?: string | undefined;
  state?: string | undefined;
  matchers?: RecordMatcher[] | undefined;
  startSeq?: string | undefined;
}

export interface RecordMatcher {
  database?: string | undefined;
  table?: string | undefined;
  primaryKeys?: any[] | undefined;
}

export interface NefarioSubscription {
  id?: string | undefined;
  state?: string | undefined;
  processUid?: string | undefined;
  channel?: string | undefined;
  startSeq?: string | undefined;
}

export interface Unsubscribe {
  id?: string | undefined;
}

export interface CreateMailboxRequest {
  channels?: string[] | undefined;
  privateMetadata?: { [key: string]: any } | undefined;
  publicMetadata?: { [key: string]: any } | undefined;
  purgeTimeoutInMillis?: number | undefined;
  closeTimeoutInMillis?: number | undefined;
  extraData?: { [key: string]: any } | undefined;
}

export interface CreateMailboxResponse {
  adminKey?: string | undefined;
  address?: string | undefined;
  readerKey?: string | undefined;
  channels?: string[] | undefined;
}

export interface AddChannelRequest {
  adminKey?: string | undefined;
  channels?: string[] | undefined;
}

export interface AddChannelResponse {
}

function createBaseMessageFromClient(): MessageFromClient {
  return {
    sendMessageRequest: undefined,
    firstMessage: undefined,
    ping: undefined,
    pong: undefined,
    notification: undefined,
    subscribeRequest: undefined,
  };
}

export const MessageFromClient = {
  encode(message: MessageFromClient, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sendMessageRequest !== undefined) {
      SendMessageRequest.encode(message.sendMessageRequest, writer.uint32(10).fork()).ldelim();
    }
    if (message.firstMessage !== undefined) {
      FirstMessage.encode(message.firstMessage, writer.uint32(18).fork()).ldelim();
    }
    if (message.ping !== undefined) {
      Ping.encode(message.ping, writer.uint32(26).fork()).ldelim();
    }
    if (message.pong !== undefined) {
      Pong.encode(message.pong, writer.uint32(34).fork()).ldelim();
    }
    if (message.notification !== undefined) {
      Notification.encode(message.notification, writer.uint32(42).fork()).ldelim();
    }
    if (message.subscribeRequest !== undefined) {
      SubscribeRequest.encode(message.subscribeRequest, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageFromClient {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageFromClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sendMessageRequest = SendMessageRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstMessage = FirstMessage.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ping = Ping.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pong = Pong.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.notification = Notification.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.subscribeRequest = SubscribeRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MessageFromClient {
    return {
      sendMessageRequest: isSet(object.sendMessageRequest)
        ? SendMessageRequest.fromJSON(object.sendMessageRequest)
        : undefined,
      firstMessage: isSet(object.firstMessage) ? FirstMessage.fromJSON(object.firstMessage) : undefined,
      ping: isSet(object.ping) ? Ping.fromJSON(object.ping) : undefined,
      pong: isSet(object.pong) ? Pong.fromJSON(object.pong) : undefined,
      notification: isSet(object.notification) ? Notification.fromJSON(object.notification) : undefined,
      subscribeRequest: isSet(object.subscribeRequest) ? SubscribeRequest.fromJSON(object.subscribeRequest) : undefined,
    };
  },

  toJSON(message: MessageFromClient): unknown {
    const obj: any = {};
    if (message.sendMessageRequest !== undefined) {
      obj.sendMessageRequest = SendMessageRequest.toJSON(message.sendMessageRequest);
    }
    if (message.firstMessage !== undefined) {
      obj.firstMessage = FirstMessage.toJSON(message.firstMessage);
    }
    if (message.ping !== undefined) {
      obj.ping = Ping.toJSON(message.ping);
    }
    if (message.pong !== undefined) {
      obj.pong = Pong.toJSON(message.pong);
    }
    if (message.notification !== undefined) {
      obj.notification = Notification.toJSON(message.notification);
    }
    if (message.subscribeRequest !== undefined) {
      obj.subscribeRequest = SubscribeRequest.toJSON(message.subscribeRequest);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageFromClient>, I>>(base?: I): MessageFromClient {
    return MessageFromClient.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MessageFromClient>, I>>(object: I): MessageFromClient {
    const message = createBaseMessageFromClient();
    message.sendMessageRequest = (object.sendMessageRequest !== undefined && object.sendMessageRequest !== null)
      ? SendMessageRequest.fromPartial(object.sendMessageRequest)
      : undefined;
    message.firstMessage = (object.firstMessage !== undefined && object.firstMessage !== null)
      ? FirstMessage.fromPartial(object.firstMessage)
      : undefined;
    message.ping = (object.ping !== undefined && object.ping !== null) ? Ping.fromPartial(object.ping) : undefined;
    message.pong = (object.pong !== undefined && object.pong !== null) ? Pong.fromPartial(object.pong) : undefined;
    message.notification = (object.notification !== undefined && object.notification !== null)
      ? Notification.fromPartial(object.notification)
      : undefined;
    message.subscribeRequest = (object.subscribeRequest !== undefined && object.subscribeRequest !== null)
      ? SubscribeRequest.fromPartial(object.subscribeRequest)
      : undefined;
    return message;
  },
};

function createBaseNotification(): Notification {
  return { message: "" };
}

export const Notification = {
  encode(message: Notification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== undefined && message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Notification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Notification {
    return { message: isSet(object.message) ? globalThis.String(object.message) : "" };
  },

  toJSON(message: Notification): unknown {
    const obj: any = {};
    if (message.message !== undefined && message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Notification>, I>>(base?: I): Notification {
    return Notification.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Notification>, I>>(object: I): Notification {
    const message = createBaseNotification();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseMessageToClient(): MessageToClient {
  return {
    messageEnvelope: undefined,
    sendMessageResponse: undefined,
    ping: undefined,
    pong: undefined,
    notification: undefined,
    subscribeResponse: undefined,
  };
}

export const MessageToClient = {
  encode(message: MessageToClient, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.messageEnvelope !== undefined) {
      MessageEnvelope.encode(message.messageEnvelope, writer.uint32(10).fork()).ldelim();
    }
    if (message.sendMessageResponse !== undefined) {
      SendMessageResponse.encode(message.sendMessageResponse, writer.uint32(18).fork()).ldelim();
    }
    if (message.ping !== undefined) {
      Ping.encode(message.ping, writer.uint32(26).fork()).ldelim();
    }
    if (message.pong !== undefined) {
      Pong.encode(message.pong, writer.uint32(34).fork()).ldelim();
    }
    if (message.notification !== undefined) {
      Notification.encode(message.notification, writer.uint32(42).fork()).ldelim();
    }
    if (message.subscribeResponse !== undefined) {
      SubscribeResponse.encode(message.subscribeResponse, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageToClient {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageToClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.messageEnvelope = MessageEnvelope.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sendMessageResponse = SendMessageResponse.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ping = Ping.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pong = Pong.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.notification = Notification.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.subscribeResponse = SubscribeResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MessageToClient {
    return {
      messageEnvelope: isSet(object.messageEnvelope) ? MessageEnvelope.fromJSON(object.messageEnvelope) : undefined,
      sendMessageResponse: isSet(object.sendMessageResponse)
        ? SendMessageResponse.fromJSON(object.sendMessageResponse)
        : undefined,
      ping: isSet(object.ping) ? Ping.fromJSON(object.ping) : undefined,
      pong: isSet(object.pong) ? Pong.fromJSON(object.pong) : undefined,
      notification: isSet(object.notification) ? Notification.fromJSON(object.notification) : undefined,
      subscribeResponse: isSet(object.subscribeResponse)
        ? SubscribeResponse.fromJSON(object.subscribeResponse)
        : undefined,
    };
  },

  toJSON(message: MessageToClient): unknown {
    const obj: any = {};
    if (message.messageEnvelope !== undefined) {
      obj.messageEnvelope = MessageEnvelope.toJSON(message.messageEnvelope);
    }
    if (message.sendMessageResponse !== undefined) {
      obj.sendMessageResponse = SendMessageResponse.toJSON(message.sendMessageResponse);
    }
    if (message.ping !== undefined) {
      obj.ping = Ping.toJSON(message.ping);
    }
    if (message.pong !== undefined) {
      obj.pong = Pong.toJSON(message.pong);
    }
    if (message.notification !== undefined) {
      obj.notification = Notification.toJSON(message.notification);
    }
    if (message.subscribeResponse !== undefined) {
      obj.subscribeResponse = SubscribeResponse.toJSON(message.subscribeResponse);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageToClient>, I>>(base?: I): MessageToClient {
    return MessageToClient.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MessageToClient>, I>>(object: I): MessageToClient {
    const message = createBaseMessageToClient();
    message.messageEnvelope = (object.messageEnvelope !== undefined && object.messageEnvelope !== null)
      ? MessageEnvelope.fromPartial(object.messageEnvelope)
      : undefined;
    message.sendMessageResponse = (object.sendMessageResponse !== undefined && object.sendMessageResponse !== null)
      ? SendMessageResponse.fromPartial(object.sendMessageResponse)
      : undefined;
    message.ping = (object.ping !== undefined && object.ping !== null) ? Ping.fromPartial(object.ping) : undefined;
    message.pong = (object.pong !== undefined && object.pong !== null) ? Pong.fromPartial(object.pong) : undefined;
    message.notification = (object.notification !== undefined && object.notification !== null)
      ? Notification.fromPartial(object.notification)
      : undefined;
    message.subscribeResponse = (object.subscribeResponse !== undefined && object.subscribeResponse !== null)
      ? SubscribeResponse.fromPartial(object.subscribeResponse)
      : undefined;
    return message;
  },
};

function createBasePing(): Ping {
  return { payload: new Uint8Array(0) };
}

export const Ping = {
  encode(message: Ping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.payload !== undefined && message.payload.length !== 0) {
      writer.uint32(10).bytes(message.payload);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ping {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payload = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ping {
    return { payload: isSet(object.payload) ? bytesFromBase64(object.payload) : new Uint8Array(0) };
  },

  toJSON(message: Ping): unknown {
    const obj: any = {};
    if (message.payload !== undefined && message.payload.length !== 0) {
      obj.payload = base64FromBytes(message.payload);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ping>, I>>(base?: I): Ping {
    return Ping.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ping>, I>>(object: I): Ping {
    const message = createBasePing();
    message.payload = object.payload ?? new Uint8Array(0);
    return message;
  },
};

function createBasePong(): Pong {
  return { payload: new Uint8Array(0) };
}

export const Pong = {
  encode(message: Pong, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.payload !== undefined && message.payload.length !== 0) {
      writer.uint32(10).bytes(message.payload);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Pong {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePong();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payload = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Pong {
    return { payload: isSet(object.payload) ? bytesFromBase64(object.payload) : new Uint8Array(0) };
  },

  toJSON(message: Pong): unknown {
    const obj: any = {};
    if (message.payload !== undefined && message.payload.length !== 0) {
      obj.payload = base64FromBytes(message.payload);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Pong>, I>>(base?: I): Pong {
    return Pong.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Pong>, I>>(object: I): Pong {
    const message = createBasePong();
    message.payload = object.payload ?? new Uint8Array(0);
    return message;
  },
};

function createBaseMessageHeader(): MessageHeader {
  return { sender: "", contentType: 0, rpcHeader: undefined, senderSequence: 0, extraHeaders: [] };
}

export const MessageHeader = {
  encode(message: MessageHeader, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sender !== undefined && message.sender !== "") {
      writer.uint32(10).string(message.sender);
    }
    if (message.contentType !== undefined && message.contentType !== 0) {
      writer.uint32(16).int32(message.contentType);
    }
    if (message.rpcHeader !== undefined) {
      RpcHeader.encode(message.rpcHeader, writer.uint32(26).fork()).ldelim();
    }
    if (message.senderSequence !== undefined && message.senderSequence !== 0) {
      writer.uint32(32).int64(message.senderSequence);
    }
    if (message.extraHeaders !== undefined && message.extraHeaders.length !== 0) {
      for (const v of message.extraHeaders) {
        KeyValPair.encode(v!, writer.uint32(1602).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageHeader {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageHeader();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sender = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.contentType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rpcHeader = RpcHeader.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.senderSequence = longToNumber(reader.int64() as Long);
          continue;
        case 200:
          if (tag !== 1602) {
            break;
          }

          message.extraHeaders!.push(KeyValPair.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MessageHeader {
    return {
      sender: isSet(object.sender) ? globalThis.String(object.sender) : "",
      contentType: isSet(object.contentType) ? contentTypeFromJSON(object.contentType) : 0,
      rpcHeader: isSet(object.rpcHeader) ? RpcHeader.fromJSON(object.rpcHeader) : undefined,
      senderSequence: isSet(object.senderSequence) ? globalThis.Number(object.senderSequence) : 0,
      extraHeaders: globalThis.Array.isArray(object?.extraHeaders)
        ? object.extraHeaders.map((e: any) => KeyValPair.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MessageHeader): unknown {
    const obj: any = {};
    if (message.sender !== undefined && message.sender !== "") {
      obj.sender = message.sender;
    }
    if (message.contentType !== undefined && message.contentType !== 0) {
      obj.contentType = contentTypeToJSON(message.contentType);
    }
    if (message.rpcHeader !== undefined) {
      obj.rpcHeader = RpcHeader.toJSON(message.rpcHeader);
    }
    if (message.senderSequence !== undefined && message.senderSequence !== 0) {
      obj.senderSequence = Math.round(message.senderSequence);
    }
    if (message.extraHeaders?.length) {
      obj.extraHeaders = message.extraHeaders.map((e) => KeyValPair.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageHeader>, I>>(base?: I): MessageHeader {
    return MessageHeader.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MessageHeader>, I>>(object: I): MessageHeader {
    const message = createBaseMessageHeader();
    message.sender = object.sender ?? "";
    message.contentType = object.contentType ?? 0;
    message.rpcHeader = (object.rpcHeader !== undefined && object.rpcHeader !== null)
      ? RpcHeader.fromPartial(object.rpcHeader)
      : undefined;
    message.senderSequence = object.senderSequence ?? 0;
    message.extraHeaders = object.extraHeaders?.map((e) => KeyValPair.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSenderEnvelope(): SenderEnvelope {
  return { created: 0 };
}

export const SenderEnvelope = {
  encode(message: SenderEnvelope, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.created !== undefined && message.created !== 0) {
      writer.uint32(8).int64(message.created);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SenderEnvelope {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSenderEnvelope();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.created = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SenderEnvelope {
    return { created: isSet(object.created) ? globalThis.Number(object.created) : 0 };
  },

  toJSON(message: SenderEnvelope): unknown {
    const obj: any = {};
    if (message.created !== undefined && message.created !== 0) {
      obj.created = Math.round(message.created);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SenderEnvelope>, I>>(base?: I): SenderEnvelope {
    return SenderEnvelope.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SenderEnvelope>, I>>(object: I): SenderEnvelope {
    const message = createBaseSenderEnvelope();
    message.created = object.created ?? 0;
    return message;
  },
};

function createBaseServerEnvelope(): ServerEnvelope {
  return { sequence: 0, created: 0, channel: "", subscriptionId: "" };
}

export const ServerEnvelope = {
  encode(message: ServerEnvelope, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sequence !== undefined && message.sequence !== 0) {
      writer.uint32(8).uint64(message.sequence);
    }
    if (message.created !== undefined && message.created !== 0) {
      writer.uint32(16).int64(message.created);
    }
    if (message.channel !== undefined && message.channel !== "") {
      writer.uint32(26).string(message.channel);
    }
    if (message.subscriptionId !== undefined && message.subscriptionId !== "") {
      writer.uint32(34).string(message.subscriptionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerEnvelope {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerEnvelope();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sequence = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.created = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.channel = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.subscriptionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerEnvelope {
    return {
      sequence: isSet(object.sequence) ? globalThis.Number(object.sequence) : 0,
      created: isSet(object.created) ? globalThis.Number(object.created) : 0,
      channel: isSet(object.channel) ? globalThis.String(object.channel) : "",
      subscriptionId: isSet(object.subscriptionId) ? globalThis.String(object.subscriptionId) : "",
    };
  },

  toJSON(message: ServerEnvelope): unknown {
    const obj: any = {};
    if (message.sequence !== undefined && message.sequence !== 0) {
      obj.sequence = Math.round(message.sequence);
    }
    if (message.created !== undefined && message.created !== 0) {
      obj.created = Math.round(message.created);
    }
    if (message.channel !== undefined && message.channel !== "") {
      obj.channel = message.channel;
    }
    if (message.subscriptionId !== undefined && message.subscriptionId !== "") {
      obj.subscriptionId = message.subscriptionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerEnvelope>, I>>(base?: I): ServerEnvelope {
    return ServerEnvelope.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerEnvelope>, I>>(object: I): ServerEnvelope {
    const message = createBaseServerEnvelope();
    message.sequence = object.sequence ?? 0;
    message.created = object.created ?? 0;
    message.channel = object.channel ?? "";
    message.subscriptionId = object.subscriptionId ?? "";
    return message;
  },
};

function createBaseKeyValPair(): KeyValPair {
  return { key: "", val: "" };
}

export const KeyValPair = {
  encode(message: KeyValPair, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== undefined && message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.val !== undefined && message.val !== "") {
      writer.uint32(18).string(message.val);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KeyValPair {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKeyValPair();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.val = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KeyValPair {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      val: isSet(object.val) ? globalThis.String(object.val) : "",
    };
  },

  toJSON(message: KeyValPair): unknown {
    const obj: any = {};
    if (message.key !== undefined && message.key !== "") {
      obj.key = message.key;
    }
    if (message.val !== undefined && message.val !== "") {
      obj.val = message.val;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KeyValPair>, I>>(base?: I): KeyValPair {
    return KeyValPair.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KeyValPair>, I>>(object: I): KeyValPair {
    const message = createBaseKeyValPair();
    message.key = object.key ?? "";
    message.val = object.val ?? "";
    return message;
  },
};

function createBaseRpcHeader(): RpcHeader {
  return { correlationId: "", endPoint: "", frameType: 0, errorInfo: undefined };
}

export const RpcHeader = {
  encode(message: RpcHeader, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.correlationId !== undefined && message.correlationId !== "") {
      writer.uint32(18).string(message.correlationId);
    }
    if (message.endPoint !== undefined && message.endPoint !== "") {
      writer.uint32(26).string(message.endPoint);
    }
    if (message.frameType !== undefined && message.frameType !== 0) {
      writer.uint32(32).int32(message.frameType);
    }
    if (message.errorInfo !== undefined) {
      RpcErrorInfo.encode(message.errorInfo, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RpcHeader {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRpcHeader();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.correlationId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endPoint = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.frameType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.errorInfo = RpcErrorInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RpcHeader {
    return {
      correlationId: isSet(object.correlationId) ? globalThis.String(object.correlationId) : "",
      endPoint: isSet(object.endPoint) ? globalThis.String(object.endPoint) : "",
      frameType: isSet(object.frameType) ? rpcFrameTypeFromJSON(object.frameType) : 0,
      errorInfo: isSet(object.errorInfo) ? RpcErrorInfo.fromJSON(object.errorInfo) : undefined,
    };
  },

  toJSON(message: RpcHeader): unknown {
    const obj: any = {};
    if (message.correlationId !== undefined && message.correlationId !== "") {
      obj.correlationId = message.correlationId;
    }
    if (message.endPoint !== undefined && message.endPoint !== "") {
      obj.endPoint = message.endPoint;
    }
    if (message.frameType !== undefined && message.frameType !== 0) {
      obj.frameType = rpcFrameTypeToJSON(message.frameType);
    }
    if (message.errorInfo !== undefined) {
      obj.errorInfo = RpcErrorInfo.toJSON(message.errorInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RpcHeader>, I>>(base?: I): RpcHeader {
    return RpcHeader.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RpcHeader>, I>>(object: I): RpcHeader {
    const message = createBaseRpcHeader();
    message.correlationId = object.correlationId ?? "";
    message.endPoint = object.endPoint ?? "";
    message.frameType = object.frameType ?? 0;
    message.errorInfo = (object.errorInfo !== undefined && object.errorInfo !== null)
      ? RpcErrorInfo.fromPartial(object.errorInfo)
      : undefined;
    return message;
  },
};

function createBaseRpcErrorInfo(): RpcErrorInfo {
  return { errorCode: 0, message: "", stackTrace: "" };
}

export const RpcErrorInfo = {
  encode(message: RpcErrorInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.errorCode !== undefined && message.errorCode !== 0) {
      writer.uint32(8).uint32(message.errorCode);
    }
    if (message.message !== undefined && message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    if (message.stackTrace !== undefined && message.stackTrace !== "") {
      writer.uint32(26).string(message.stackTrace);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RpcErrorInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRpcErrorInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.errorCode = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.stackTrace = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RpcErrorInfo {
    return {
      errorCode: isSet(object.errorCode) ? globalThis.Number(object.errorCode) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      stackTrace: isSet(object.stackTrace) ? globalThis.String(object.stackTrace) : "",
    };
  },

  toJSON(message: RpcErrorInfo): unknown {
    const obj: any = {};
    if (message.errorCode !== undefined && message.errorCode !== 0) {
      obj.errorCode = Math.round(message.errorCode);
    }
    if (message.message !== undefined && message.message !== "") {
      obj.message = message.message;
    }
    if (message.stackTrace !== undefined && message.stackTrace !== "") {
      obj.stackTrace = message.stackTrace;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RpcErrorInfo>, I>>(base?: I): RpcErrorInfo {
    return RpcErrorInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RpcErrorInfo>, I>>(object: I): RpcErrorInfo {
    const message = createBaseRpcErrorInfo();
    message.errorCode = object.errorCode ?? 0;
    message.message = object.message ?? "";
    message.stackTrace = object.stackTrace ?? "";
    return message;
  },
};

function createBaseMessage(): Message {
  return { header: undefined, senderEnvelope: undefined, serverEnvelope: undefined, data: new Uint8Array(0) };
}

export const Message = {
  encode(message: Message, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.header !== undefined) {
      MessageHeader.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.senderEnvelope !== undefined) {
      SenderEnvelope.encode(message.senderEnvelope, writer.uint32(18).fork()).ldelim();
    }
    if (message.serverEnvelope !== undefined) {
      ServerEnvelope.encode(message.serverEnvelope, writer.uint32(26).fork()).ldelim();
    }
    if (message.data !== undefined && message.data.length !== 0) {
      writer.uint32(34).bytes(message.data);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.header = MessageHeader.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.senderEnvelope = SenderEnvelope.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serverEnvelope = ServerEnvelope.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.data = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Message {
    return {
      header: isSet(object.header) ? MessageHeader.fromJSON(object.header) : undefined,
      senderEnvelope: isSet(object.senderEnvelope) ? SenderEnvelope.fromJSON(object.senderEnvelope) : undefined,
      serverEnvelope: isSet(object.serverEnvelope) ? ServerEnvelope.fromJSON(object.serverEnvelope) : undefined,
      data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(0),
    };
  },

  toJSON(message: Message): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = MessageHeader.toJSON(message.header);
    }
    if (message.senderEnvelope !== undefined) {
      obj.senderEnvelope = SenderEnvelope.toJSON(message.senderEnvelope);
    }
    if (message.serverEnvelope !== undefined) {
      obj.serverEnvelope = ServerEnvelope.toJSON(message.serverEnvelope);
    }
    if (message.data !== undefined && message.data.length !== 0) {
      obj.data = base64FromBytes(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Message>, I>>(base?: I): Message {
    return Message.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Message>, I>>(object: I): Message {
    const message = createBaseMessage();
    message.header = (object.header !== undefined && object.header !== null)
      ? MessageHeader.fromPartial(object.header)
      : undefined;
    message.senderEnvelope = (object.senderEnvelope !== undefined && object.senderEnvelope !== null)
      ? SenderEnvelope.fromPartial(object.senderEnvelope)
      : undefined;
    message.serverEnvelope = (object.serverEnvelope !== undefined && object.serverEnvelope !== null)
      ? ServerEnvelope.fromPartial(object.serverEnvelope)
      : undefined;
    message.data = object.data ?? new Uint8Array(0);
    return message;
  },
};

function createBaseMessageEnvelope(): MessageEnvelope {
  return { messageBytes: new Uint8Array(0), serverEnvelope: undefined };
}

export const MessageEnvelope = {
  encode(message: MessageEnvelope, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.messageBytes !== undefined && message.messageBytes.length !== 0) {
      writer.uint32(10).bytes(message.messageBytes);
    }
    if (message.serverEnvelope !== undefined) {
      ServerEnvelope.encode(message.serverEnvelope, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageEnvelope {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageEnvelope();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.messageBytes = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverEnvelope = ServerEnvelope.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MessageEnvelope {
    return {
      messageBytes: isSet(object.messageBytes) ? bytesFromBase64(object.messageBytes) : new Uint8Array(0),
      serverEnvelope: isSet(object.serverEnvelope) ? ServerEnvelope.fromJSON(object.serverEnvelope) : undefined,
    };
  },

  toJSON(message: MessageEnvelope): unknown {
    const obj: any = {};
    if (message.messageBytes !== undefined && message.messageBytes.length !== 0) {
      obj.messageBytes = base64FromBytes(message.messageBytes);
    }
    if (message.serverEnvelope !== undefined) {
      obj.serverEnvelope = ServerEnvelope.toJSON(message.serverEnvelope);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageEnvelope>, I>>(base?: I): MessageEnvelope {
    return MessageEnvelope.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MessageEnvelope>, I>>(object: I): MessageEnvelope {
    const message = createBaseMessageEnvelope();
    message.messageBytes = object.messageBytes ?? new Uint8Array(0);
    message.serverEnvelope = (object.serverEnvelope !== undefined && object.serverEnvelope !== null)
      ? ServerEnvelope.fromPartial(object.serverEnvelope)
      : undefined;
    return message;
  },
};

function createBaseSendMessageRequest(): SendMessageRequest {
  return { to: [], message: undefined, channel: "", idempotentId: "" };
}

export const SendMessageRequest = {
  encode(message: SendMessageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.to !== undefined && message.to.length !== 0) {
      for (const v of message.to) {
        writer.uint32(10).string(v!);
      }
    }
    if (message.message !== undefined) {
      Message.encode(message.message, writer.uint32(18).fork()).ldelim();
    }
    if (message.channel !== undefined && message.channel !== "") {
      writer.uint32(26).string(message.channel);
    }
    if (message.idempotentId !== undefined && message.idempotentId !== "") {
      writer.uint32(34).string(message.idempotentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendMessageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendMessageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.to!.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = Message.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.channel = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.idempotentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendMessageRequest {
    return {
      to: globalThis.Array.isArray(object?.to) ? object.to.map((e: any) => globalThis.String(e)) : [],
      message: isSet(object.message) ? Message.fromJSON(object.message) : undefined,
      channel: isSet(object.channel) ? globalThis.String(object.channel) : "",
      idempotentId: isSet(object.idempotentId) ? globalThis.String(object.idempotentId) : "",
    };
  },

  toJSON(message: SendMessageRequest): unknown {
    const obj: any = {};
    if (message.to?.length) {
      obj.to = message.to;
    }
    if (message.message !== undefined) {
      obj.message = Message.toJSON(message.message);
    }
    if (message.channel !== undefined && message.channel !== "") {
      obj.channel = message.channel;
    }
    if (message.idempotentId !== undefined && message.idempotentId !== "") {
      obj.idempotentId = message.idempotentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendMessageRequest>, I>>(base?: I): SendMessageRequest {
    return SendMessageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendMessageRequest>, I>>(object: I): SendMessageRequest {
    const message = createBaseSendMessageRequest();
    message.to = object.to?.map((e) => e) || [];
    message.message = (object.message !== undefined && object.message !== null)
      ? Message.fromPartial(object.message)
      : undefined;
    message.channel = object.channel ?? "";
    message.idempotentId = object.idempotentId ?? "";
    return message;
  },
};

function createBaseSendMessageResponse(): SendMessageResponse {
  return { errors: [], duplicates: [], idempotentId: "", correlationId: "" };
}

export const SendMessageResponse = {
  encode(message: SendMessageResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.errors !== undefined && message.errors.length !== 0) {
      for (const v of message.errors) {
        SendMessageError.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.duplicates !== undefined && message.duplicates.length !== 0) {
      for (const v of message.duplicates) {
        writer.uint32(18).string(v!);
      }
    }
    if (message.idempotentId !== undefined && message.idempotentId !== "") {
      writer.uint32(26).string(message.idempotentId);
    }
    if (message.correlationId !== undefined && message.correlationId !== "") {
      writer.uint32(34).string(message.correlationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendMessageResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendMessageResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.errors!.push(SendMessageError.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.duplicates!.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.idempotentId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.correlationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendMessageResponse {
    return {
      errors: globalThis.Array.isArray(object?.errors)
        ? object.errors.map((e: any) => SendMessageError.fromJSON(e))
        : [],
      duplicates: globalThis.Array.isArray(object?.duplicates)
        ? object.duplicates.map((e: any) => globalThis.String(e))
        : [],
      idempotentId: isSet(object.idempotentId) ? globalThis.String(object.idempotentId) : "",
      correlationId: isSet(object.correlationId) ? globalThis.String(object.correlationId) : "",
    };
  },

  toJSON(message: SendMessageResponse): unknown {
    const obj: any = {};
    if (message.errors?.length) {
      obj.errors = message.errors.map((e) => SendMessageError.toJSON(e));
    }
    if (message.duplicates?.length) {
      obj.duplicates = message.duplicates;
    }
    if (message.idempotentId !== undefined && message.idempotentId !== "") {
      obj.idempotentId = message.idempotentId;
    }
    if (message.correlationId !== undefined && message.correlationId !== "") {
      obj.correlationId = message.correlationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendMessageResponse>, I>>(base?: I): SendMessageResponse {
    return SendMessageResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendMessageResponse>, I>>(object: I): SendMessageResponse {
    const message = createBaseSendMessageResponse();
    message.errors = object.errors?.map((e) => SendMessageError.fromPartial(e)) || [];
    message.duplicates = object.duplicates?.map((e) => e) || [];
    message.idempotentId = object.idempotentId ?? "";
    message.correlationId = object.correlationId ?? "";
    return message;
  },
};

function createBaseSendReceipt(): SendReceipt {
  return { request: undefined, response: undefined, serverEnvelope: undefined };
}

export const SendReceipt = {
  encode(message: SendReceipt, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      SendMessageRequest.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    if (message.response !== undefined) {
      SendMessageResponse.encode(message.response, writer.uint32(18).fork()).ldelim();
    }
    if (message.serverEnvelope !== undefined) {
      ServerEnvelope.encode(message.serverEnvelope, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendReceipt {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendReceipt();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = SendMessageRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.response = SendMessageResponse.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serverEnvelope = ServerEnvelope.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendReceipt {
    return {
      request: isSet(object.request) ? SendMessageRequest.fromJSON(object.request) : undefined,
      response: isSet(object.response) ? SendMessageResponse.fromJSON(object.response) : undefined,
      serverEnvelope: isSet(object.serverEnvelope) ? ServerEnvelope.fromJSON(object.serverEnvelope) : undefined,
    };
  },

  toJSON(message: SendReceipt): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = SendMessageRequest.toJSON(message.request);
    }
    if (message.response !== undefined) {
      obj.response = SendMessageResponse.toJSON(message.response);
    }
    if (message.serverEnvelope !== undefined) {
      obj.serverEnvelope = ServerEnvelope.toJSON(message.serverEnvelope);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendReceipt>, I>>(base?: I): SendReceipt {
    return SendReceipt.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendReceipt>, I>>(object: I): SendReceipt {
    const message = createBaseSendReceipt();
    message.request = (object.request !== undefined && object.request !== null)
      ? SendMessageRequest.fromPartial(object.request)
      : undefined;
    message.response = (object.response !== undefined && object.response !== null)
      ? SendMessageResponse.fromPartial(object.response)
      : undefined;
    message.serverEnvelope = (object.serverEnvelope !== undefined && object.serverEnvelope !== null)
      ? ServerEnvelope.fromPartial(object.serverEnvelope)
      : undefined;
    return message;
  },
};

function createBaseSendMessageError(): SendMessageError {
  return { message: "", to: "" };
}

export const SendMessageError = {
  encode(message: SendMessageError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== undefined && message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    if (message.to !== undefined && message.to !== "") {
      writer.uint32(18).string(message.to);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendMessageError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendMessageError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendMessageError {
    return {
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      to: isSet(object.to) ? globalThis.String(object.to) : "",
    };
  },

  toJSON(message: SendMessageError): unknown {
    const obj: any = {};
    if (message.message !== undefined && message.message !== "") {
      obj.message = message.message;
    }
    if (message.to !== undefined && message.to !== "") {
      obj.to = message.to;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SendMessageError>, I>>(base?: I): SendMessageError {
    return SendMessageError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendMessageError>, I>>(object: I): SendMessageError {
    const message = createBaseSendMessageError();
    message.message = object.message ?? "";
    message.to = object.to ?? "";
    return message;
  },
};

function createBaseFirstMessage(): FirstMessage {
  return { senderInfo: undefined, mailboxTimeoutInMs: 0, subscriptions: [] };
}

export const FirstMessage = {
  encode(message: FirstMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.senderInfo !== undefined) {
      SenderInfo.encode(message.senderInfo, writer.uint32(10).fork()).ldelim();
    }
    if (message.mailboxTimeoutInMs !== undefined && message.mailboxTimeoutInMs !== 0) {
      writer.uint32(16).uint32(message.mailboxTimeoutInMs);
    }
    if (message.subscriptions !== undefined && message.subscriptions.length !== 0) {
      for (const v of message.subscriptions) {
        Subscription.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FirstMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFirstMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.senderInfo = SenderInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.mailboxTimeoutInMs = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subscriptions!.push(Subscription.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FirstMessage {
    return {
      senderInfo: isSet(object.senderInfo) ? SenderInfo.fromJSON(object.senderInfo) : undefined,
      mailboxTimeoutInMs: isSet(object.mailboxTimeoutInMs) ? globalThis.Number(object.mailboxTimeoutInMs) : 0,
      subscriptions: globalThis.Array.isArray(object?.subscriptions)
        ? object.subscriptions.map((e: any) => Subscription.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FirstMessage): unknown {
    const obj: any = {};
    if (message.senderInfo !== undefined) {
      obj.senderInfo = SenderInfo.toJSON(message.senderInfo);
    }
    if (message.mailboxTimeoutInMs !== undefined && message.mailboxTimeoutInMs !== 0) {
      obj.mailboxTimeoutInMs = Math.round(message.mailboxTimeoutInMs);
    }
    if (message.subscriptions?.length) {
      obj.subscriptions = message.subscriptions.map((e) => Subscription.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FirstMessage>, I>>(base?: I): FirstMessage {
    return FirstMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FirstMessage>, I>>(object: I): FirstMessage {
    const message = createBaseFirstMessage();
    message.senderInfo = (object.senderInfo !== undefined && object.senderInfo !== null)
      ? SenderInfo.fromPartial(object.senderInfo)
      : undefined;
    message.mailboxTimeoutInMs = object.mailboxTimeoutInMs ?? 0;
    message.subscriptions = object.subscriptions?.map((e) => Subscription.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSenderInfo(): SenderInfo {
  return { readerKey: "", address: "" };
}

export const SenderInfo = {
  encode(message: SenderInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.readerKey !== undefined && message.readerKey !== "") {
      writer.uint32(10).string(message.readerKey);
    }
    if (message.address !== undefined && message.address !== "") {
      writer.uint32(18).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SenderInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSenderInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.readerKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SenderInfo {
    return {
      readerKey: isSet(object.readerKey) ? globalThis.String(object.readerKey) : "",
      address: isSet(object.address) ? globalThis.String(object.address) : "",
    };
  },

  toJSON(message: SenderInfo): unknown {
    const obj: any = {};
    if (message.readerKey !== undefined && message.readerKey !== "") {
      obj.readerKey = message.readerKey;
    }
    if (message.address !== undefined && message.address !== "") {
      obj.address = message.address;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SenderInfo>, I>>(base?: I): SenderInfo {
    return SenderInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SenderInfo>, I>>(object: I): SenderInfo {
    const message = createBaseSenderInfo();
    message.readerKey = object.readerKey ?? "";
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseSubscribeRequest(): SubscribeRequest {
  return { subscriptions: [] };
}

export const SubscribeRequest = {
  encode(message: SubscribeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.subscriptions !== undefined && message.subscriptions.length !== 0) {
      for (const v of message.subscriptions) {
        Subscription.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscribeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.subscriptions!.push(Subscription.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscribeRequest {
    return {
      subscriptions: globalThis.Array.isArray(object?.subscriptions)
        ? object.subscriptions.map((e: any) => Subscription.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SubscribeRequest): unknown {
    const obj: any = {};
    if (message.subscriptions?.length) {
      obj.subscriptions = message.subscriptions.map((e) => Subscription.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscribeRequest>, I>>(base?: I): SubscribeRequest {
    return SubscribeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubscribeRequest>, I>>(object: I): SubscribeRequest {
    const message = createBaseSubscribeRequest();
    message.subscriptions = object.subscriptions?.map((e) => Subscription.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSubscribeResponse(): SubscribeResponse {
  return { succeeded: [], errors: [] };
}

export const SubscribeResponse = {
  encode(message: SubscribeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.succeeded !== undefined && message.succeeded.length !== 0) {
      for (const v of message.succeeded) {
        writer.uint32(10).string(v!);
      }
    }
    if (message.errors !== undefined && message.errors.length !== 0) {
      for (const v of message.errors) {
        SubscribeError.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscribeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.succeeded!.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errors!.push(SubscribeError.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscribeResponse {
    return {
      succeeded: globalThis.Array.isArray(object?.succeeded)
        ? object.succeeded.map((e: any) => globalThis.String(e))
        : [],
      errors: globalThis.Array.isArray(object?.errors) ? object.errors.map((e: any) => SubscribeError.fromJSON(e)) : [],
    };
  },

  toJSON(message: SubscribeResponse): unknown {
    const obj: any = {};
    if (message.succeeded?.length) {
      obj.succeeded = message.succeeded;
    }
    if (message.errors?.length) {
      obj.errors = message.errors.map((e) => SubscribeError.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscribeResponse>, I>>(base?: I): SubscribeResponse {
    return SubscribeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubscribeResponse>, I>>(object: I): SubscribeResponse {
    const message = createBaseSubscribeResponse();
    message.succeeded = object.succeeded?.map((e) => e) || [];
    message.errors = object.errors?.map((e) => SubscribeError.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSubscribeError(): SubscribeError {
  return { state: "", message: "" };
}

export const SubscribeError = {
  encode(message: SubscribeError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.state !== undefined && message.state !== "") {
      writer.uint32(18).string(message.state);
    }
    if (message.message !== undefined && message.message !== "") {
      writer.uint32(26).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscribeError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.state = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscribeError {
    return {
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: SubscribeError): unknown {
    const obj: any = {};
    if (message.state !== undefined && message.state !== "") {
      obj.state = message.state;
    }
    if (message.message !== undefined && message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscribeError>, I>>(base?: I): SubscribeError {
    return SubscribeError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubscribeError>, I>>(object: I): SubscribeError {
    const message = createBaseSubscribeError();
    message.state = object.state ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseSubscription(): Subscription {
  return { mailbox: undefined, nefario: undefined, changeDataCapture: undefined, unsubscribe: undefined };
}

export const Subscription = {
  encode(message: Subscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mailbox !== undefined) {
      MailboxSubscription.encode(message.mailbox, writer.uint32(10).fork()).ldelim();
    }
    if (message.nefario !== undefined) {
      NefarioSubscription.encode(message.nefario, writer.uint32(18).fork()).ldelim();
    }
    if (message.changeDataCapture !== undefined) {
      ChangeDataCaptureSubscription.encode(message.changeDataCapture, writer.uint32(26).fork()).ldelim();
    }
    if (message.unsubscribe !== undefined) {
      Unsubscribe.encode(message.unsubscribe, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Subscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mailbox = MailboxSubscription.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nefario = NefarioSubscription.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.changeDataCapture = ChangeDataCaptureSubscription.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.unsubscribe = Unsubscribe.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Subscription {
    return {
      mailbox: isSet(object.mailbox) ? MailboxSubscription.fromJSON(object.mailbox) : undefined,
      nefario: isSet(object.nefario) ? NefarioSubscription.fromJSON(object.nefario) : undefined,
      changeDataCapture: isSet(object.changeDataCapture)
        ? ChangeDataCaptureSubscription.fromJSON(object.changeDataCapture)
        : undefined,
      unsubscribe: isSet(object.unsubscribe) ? Unsubscribe.fromJSON(object.unsubscribe) : undefined,
    };
  },

  toJSON(message: Subscription): unknown {
    const obj: any = {};
    if (message.mailbox !== undefined) {
      obj.mailbox = MailboxSubscription.toJSON(message.mailbox);
    }
    if (message.nefario !== undefined) {
      obj.nefario = NefarioSubscription.toJSON(message.nefario);
    }
    if (message.changeDataCapture !== undefined) {
      obj.changeDataCapture = ChangeDataCaptureSubscription.toJSON(message.changeDataCapture);
    }
    if (message.unsubscribe !== undefined) {
      obj.unsubscribe = Unsubscribe.toJSON(message.unsubscribe);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Subscription>, I>>(base?: I): Subscription {
    return Subscription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Subscription>, I>>(object: I): Subscription {
    const message = createBaseSubscription();
    message.mailbox = (object.mailbox !== undefined && object.mailbox !== null)
      ? MailboxSubscription.fromPartial(object.mailbox)
      : undefined;
    message.nefario = (object.nefario !== undefined && object.nefario !== null)
      ? NefarioSubscription.fromPartial(object.nefario)
      : undefined;
    message.changeDataCapture = (object.changeDataCapture !== undefined && object.changeDataCapture !== null)
      ? ChangeDataCaptureSubscription.fromPartial(object.changeDataCapture)
      : undefined;
    message.unsubscribe = (object.unsubscribe !== undefined && object.unsubscribe !== null)
      ? Unsubscribe.fromPartial(object.unsubscribe)
      : undefined;
    return message;
  },
};

function createBaseMailboxSubscription(): MailboxSubscription {
  return { id: "", state: "", readerKey: "", channel: "", startSeq: "" };
}

export const MailboxSubscription = {
  encode(message: MailboxSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.state !== undefined && message.state !== "") {
      writer.uint32(18).string(message.state);
    }
    if (message.readerKey !== undefined && message.readerKey !== "") {
      writer.uint32(26).string(message.readerKey);
    }
    if (message.channel !== undefined && message.channel !== "") {
      writer.uint32(34).string(message.channel);
    }
    if (message.startSeq !== undefined && message.startSeq !== "") {
      writer.uint32(42).string(message.startSeq);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MailboxSubscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMailboxSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.state = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.readerKey = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.channel = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.startSeq = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MailboxSubscription {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      readerKey: isSet(object.readerKey) ? globalThis.String(object.readerKey) : "",
      channel: isSet(object.channel) ? globalThis.String(object.channel) : "",
      startSeq: isSet(object.startSeq) ? globalThis.String(object.startSeq) : "",
    };
  },

  toJSON(message: MailboxSubscription): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.state !== undefined && message.state !== "") {
      obj.state = message.state;
    }
    if (message.readerKey !== undefined && message.readerKey !== "") {
      obj.readerKey = message.readerKey;
    }
    if (message.channel !== undefined && message.channel !== "") {
      obj.channel = message.channel;
    }
    if (message.startSeq !== undefined && message.startSeq !== "") {
      obj.startSeq = message.startSeq;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MailboxSubscription>, I>>(base?: I): MailboxSubscription {
    return MailboxSubscription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MailboxSubscription>, I>>(object: I): MailboxSubscription {
    const message = createBaseMailboxSubscription();
    message.id = object.id ?? "";
    message.state = object.state ?? "";
    message.readerKey = object.readerKey ?? "";
    message.channel = object.channel ?? "";
    message.startSeq = object.startSeq ?? "";
    return message;
  },
};

function createBaseChangeDataCaptureSubscription(): ChangeDataCaptureSubscription {
  return { id: "", state: "", matchers: [], startSeq: "" };
}

export const ChangeDataCaptureSubscription = {
  encode(message: ChangeDataCaptureSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.state !== undefined && message.state !== "") {
      writer.uint32(18).string(message.state);
    }
    if (message.matchers !== undefined && message.matchers.length !== 0) {
      for (const v of message.matchers) {
        RecordMatcher.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    if (message.startSeq !== undefined && message.startSeq !== "") {
      writer.uint32(34).string(message.startSeq);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChangeDataCaptureSubscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChangeDataCaptureSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.state = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.matchers!.push(RecordMatcher.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.startSeq = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChangeDataCaptureSubscription {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      matchers: globalThis.Array.isArray(object?.matchers)
        ? object.matchers.map((e: any) => RecordMatcher.fromJSON(e))
        : [],
      startSeq: isSet(object.startSeq) ? globalThis.String(object.startSeq) : "",
    };
  },

  toJSON(message: ChangeDataCaptureSubscription): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.state !== undefined && message.state !== "") {
      obj.state = message.state;
    }
    if (message.matchers?.length) {
      obj.matchers = message.matchers.map((e) => RecordMatcher.toJSON(e));
    }
    if (message.startSeq !== undefined && message.startSeq !== "") {
      obj.startSeq = message.startSeq;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChangeDataCaptureSubscription>, I>>(base?: I): ChangeDataCaptureSubscription {
    return ChangeDataCaptureSubscription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChangeDataCaptureSubscription>, I>>(
    object: I,
  ): ChangeDataCaptureSubscription {
    const message = createBaseChangeDataCaptureSubscription();
    message.id = object.id ?? "";
    message.state = object.state ?? "";
    message.matchers = object.matchers?.map((e) => RecordMatcher.fromPartial(e)) || [];
    message.startSeq = object.startSeq ?? "";
    return message;
  },
};

function createBaseRecordMatcher(): RecordMatcher {
  return { database: "", table: "", primaryKeys: [] };
}

export const RecordMatcher = {
  encode(message: RecordMatcher, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.database !== undefined && message.database !== "") {
      writer.uint32(10).string(message.database);
    }
    if (message.table !== undefined && message.table !== "") {
      writer.uint32(18).string(message.table);
    }
    if (message.primaryKeys !== undefined && message.primaryKeys.length !== 0) {
      for (const v of message.primaryKeys) {
        Value.encode(Value.wrap(v!), writer.uint32(26).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecordMatcher {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordMatcher();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.database = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.table = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.primaryKeys!.push(Value.unwrap(Value.decode(reader, reader.uint32())));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordMatcher {
    return {
      database: isSet(object.database) ? globalThis.String(object.database) : "",
      table: isSet(object.table) ? globalThis.String(object.table) : "",
      primaryKeys: globalThis.Array.isArray(object?.primaryKeys) ? [...object.primaryKeys] : [],
    };
  },

  toJSON(message: RecordMatcher): unknown {
    const obj: any = {};
    if (message.database !== undefined && message.database !== "") {
      obj.database = message.database;
    }
    if (message.table !== undefined && message.table !== "") {
      obj.table = message.table;
    }
    if (message.primaryKeys?.length) {
      obj.primaryKeys = message.primaryKeys;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordMatcher>, I>>(base?: I): RecordMatcher {
    return RecordMatcher.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RecordMatcher>, I>>(object: I): RecordMatcher {
    const message = createBaseRecordMatcher();
    message.database = object.database ?? "";
    message.table = object.table ?? "";
    message.primaryKeys = object.primaryKeys?.map((e) => e) || [];
    return message;
  },
};

function createBaseNefarioSubscription(): NefarioSubscription {
  return { id: "", state: "", processUid: "", channel: "", startSeq: "" };
}

export const NefarioSubscription = {
  encode(message: NefarioSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.state !== undefined && message.state !== "") {
      writer.uint32(18).string(message.state);
    }
    if (message.processUid !== undefined && message.processUid !== "") {
      writer.uint32(26).string(message.processUid);
    }
    if (message.channel !== undefined && message.channel !== "") {
      writer.uint32(34).string(message.channel);
    }
    if (message.startSeq !== undefined && message.startSeq !== "") {
      writer.uint32(42).string(message.startSeq);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NefarioSubscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNefarioSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.state = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.processUid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.channel = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.startSeq = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NefarioSubscription {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      processUid: isSet(object.processUid) ? globalThis.String(object.processUid) : "",
      channel: isSet(object.channel) ? globalThis.String(object.channel) : "",
      startSeq: isSet(object.startSeq) ? globalThis.String(object.startSeq) : "",
    };
  },

  toJSON(message: NefarioSubscription): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.state !== undefined && message.state !== "") {
      obj.state = message.state;
    }
    if (message.processUid !== undefined && message.processUid !== "") {
      obj.processUid = message.processUid;
    }
    if (message.channel !== undefined && message.channel !== "") {
      obj.channel = message.channel;
    }
    if (message.startSeq !== undefined && message.startSeq !== "") {
      obj.startSeq = message.startSeq;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NefarioSubscription>, I>>(base?: I): NefarioSubscription {
    return NefarioSubscription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NefarioSubscription>, I>>(object: I): NefarioSubscription {
    const message = createBaseNefarioSubscription();
    message.id = object.id ?? "";
    message.state = object.state ?? "";
    message.processUid = object.processUid ?? "";
    message.channel = object.channel ?? "";
    message.startSeq = object.startSeq ?? "";
    return message;
  },
};

function createBaseUnsubscribe(): Unsubscribe {
  return { id: "" };
}

export const Unsubscribe = {
  encode(message: Unsubscribe, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Unsubscribe {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnsubscribe();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Unsubscribe {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: Unsubscribe): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Unsubscribe>, I>>(base?: I): Unsubscribe {
    return Unsubscribe.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Unsubscribe>, I>>(object: I): Unsubscribe {
    const message = createBaseUnsubscribe();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseCreateMailboxRequest(): CreateMailboxRequest {
  return {
    channels: [],
    privateMetadata: undefined,
    publicMetadata: undefined,
    purgeTimeoutInMillis: 0,
    closeTimeoutInMillis: 0,
    extraData: undefined,
  };
}

export const CreateMailboxRequest = {
  encode(message: CreateMailboxRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.channels !== undefined && message.channels.length !== 0) {
      for (const v of message.channels) {
        writer.uint32(10).string(v!);
      }
    }
    if (message.privateMetadata !== undefined) {
      Struct.encode(Struct.wrap(message.privateMetadata), writer.uint32(18).fork()).ldelim();
    }
    if (message.publicMetadata !== undefined) {
      Struct.encode(Struct.wrap(message.publicMetadata), writer.uint32(26).fork()).ldelim();
    }
    if (message.purgeTimeoutInMillis !== undefined && message.purgeTimeoutInMillis !== 0) {
      writer.uint32(32).int64(message.purgeTimeoutInMillis);
    }
    if (message.closeTimeoutInMillis !== undefined && message.closeTimeoutInMillis !== 0) {
      writer.uint32(40).int64(message.closeTimeoutInMillis);
    }
    if (message.extraData !== undefined) {
      Struct.encode(Struct.wrap(message.extraData), writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateMailboxRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMailboxRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.channels!.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.privateMetadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.publicMetadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.purgeTimeoutInMillis = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.closeTimeoutInMillis = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.extraData = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateMailboxRequest {
    return {
      channels: globalThis.Array.isArray(object?.channels) ? object.channels.map((e: any) => globalThis.String(e)) : [],
      privateMetadata: isObject(object.privateMetadata) ? object.privateMetadata : undefined,
      publicMetadata: isObject(object.publicMetadata) ? object.publicMetadata : undefined,
      purgeTimeoutInMillis: isSet(object.purgeTimeoutInMillis) ? globalThis.Number(object.purgeTimeoutInMillis) : 0,
      closeTimeoutInMillis: isSet(object.closeTimeoutInMillis) ? globalThis.Number(object.closeTimeoutInMillis) : 0,
      extraData: isObject(object.extraData) ? object.extraData : undefined,
    };
  },

  toJSON(message: CreateMailboxRequest): unknown {
    const obj: any = {};
    if (message.channels?.length) {
      obj.channels = message.channels;
    }
    if (message.privateMetadata !== undefined) {
      obj.privateMetadata = message.privateMetadata;
    }
    if (message.publicMetadata !== undefined) {
      obj.publicMetadata = message.publicMetadata;
    }
    if (message.purgeTimeoutInMillis !== undefined && message.purgeTimeoutInMillis !== 0) {
      obj.purgeTimeoutInMillis = Math.round(message.purgeTimeoutInMillis);
    }
    if (message.closeTimeoutInMillis !== undefined && message.closeTimeoutInMillis !== 0) {
      obj.closeTimeoutInMillis = Math.round(message.closeTimeoutInMillis);
    }
    if (message.extraData !== undefined) {
      obj.extraData = message.extraData;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateMailboxRequest>, I>>(base?: I): CreateMailboxRequest {
    return CreateMailboxRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateMailboxRequest>, I>>(object: I): CreateMailboxRequest {
    const message = createBaseCreateMailboxRequest();
    message.channels = object.channels?.map((e) => e) || [];
    message.privateMetadata = object.privateMetadata ?? undefined;
    message.publicMetadata = object.publicMetadata ?? undefined;
    message.purgeTimeoutInMillis = object.purgeTimeoutInMillis ?? 0;
    message.closeTimeoutInMillis = object.closeTimeoutInMillis ?? 0;
    message.extraData = object.extraData ?? undefined;
    return message;
  },
};

function createBaseCreateMailboxResponse(): CreateMailboxResponse {
  return { adminKey: "", address: "", readerKey: "", channels: [] };
}

export const CreateMailboxResponse = {
  encode(message: CreateMailboxResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.adminKey !== undefined && message.adminKey !== "") {
      writer.uint32(10).string(message.adminKey);
    }
    if (message.address !== undefined && message.address !== "") {
      writer.uint32(18).string(message.address);
    }
    if (message.readerKey !== undefined && message.readerKey !== "") {
      writer.uint32(26).string(message.readerKey);
    }
    if (message.channels !== undefined && message.channels.length !== 0) {
      for (const v of message.channels) {
        writer.uint32(34).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateMailboxResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMailboxResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.adminKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.address = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.readerKey = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.channels!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateMailboxResponse {
    return {
      adminKey: isSet(object.adminKey) ? globalThis.String(object.adminKey) : "",
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      readerKey: isSet(object.readerKey) ? globalThis.String(object.readerKey) : "",
      channels: globalThis.Array.isArray(object?.channels) ? object.channels.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: CreateMailboxResponse): unknown {
    const obj: any = {};
    if (message.adminKey !== undefined && message.adminKey !== "") {
      obj.adminKey = message.adminKey;
    }
    if (message.address !== undefined && message.address !== "") {
      obj.address = message.address;
    }
    if (message.readerKey !== undefined && message.readerKey !== "") {
      obj.readerKey = message.readerKey;
    }
    if (message.channels?.length) {
      obj.channels = message.channels;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateMailboxResponse>, I>>(base?: I): CreateMailboxResponse {
    return CreateMailboxResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateMailboxResponse>, I>>(object: I): CreateMailboxResponse {
    const message = createBaseCreateMailboxResponse();
    message.adminKey = object.adminKey ?? "";
    message.address = object.address ?? "";
    message.readerKey = object.readerKey ?? "";
    message.channels = object.channels?.map((e) => e) || [];
    return message;
  },
};

function createBaseAddChannelRequest(): AddChannelRequest {
  return { adminKey: "", channels: [] };
}

export const AddChannelRequest = {
  encode(message: AddChannelRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.adminKey !== undefined && message.adminKey !== "") {
      writer.uint32(10).string(message.adminKey);
    }
    if (message.channels !== undefined && message.channels.length !== 0) {
      for (const v of message.channels) {
        writer.uint32(18).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddChannelRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddChannelRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.adminKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.channels!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddChannelRequest {
    return {
      adminKey: isSet(object.adminKey) ? globalThis.String(object.adminKey) : "",
      channels: globalThis.Array.isArray(object?.channels) ? object.channels.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: AddChannelRequest): unknown {
    const obj: any = {};
    if (message.adminKey !== undefined && message.adminKey !== "") {
      obj.adminKey = message.adminKey;
    }
    if (message.channels?.length) {
      obj.channels = message.channels;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddChannelRequest>, I>>(base?: I): AddChannelRequest {
    return AddChannelRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddChannelRequest>, I>>(object: I): AddChannelRequest {
    const message = createBaseAddChannelRequest();
    message.adminKey = object.adminKey ?? "";
    message.channels = object.channels?.map((e) => e) || [];
    return message;
  },
};

function createBaseAddChannelResponse(): AddChannelResponse {
  return {};
}

export const AddChannelResponse = {
  encode(_: AddChannelResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddChannelResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddChannelResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AddChannelResponse {
    return {};
  },

  toJSON(_: AddChannelResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AddChannelResponse>, I>>(base?: I): AddChannelResponse {
    return AddChannelResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddChannelResponse>, I>>(_: I): AddChannelResponse {
    const message = createBaseAddChannelResponse();
    return message;
  },
};

export interface HermesService {
  SendReceive(request: Observable<MessageFromClient>): Observable<MessageToClient>;
  CreateMailbox(request: CreateMailboxRequest): Promise<CreateMailboxResponse>;
  AddChannel(request: AddChannelRequest): Promise<AddChannelResponse>;
}

export const HermesServiceServiceName = "hermes.HermesService";
export class HermesServiceClientImpl implements HermesService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || HermesServiceServiceName;
    this.rpc = rpc;
    this.SendReceive = this.SendReceive.bind(this);
    this.CreateMailbox = this.CreateMailbox.bind(this);
    this.AddChannel = this.AddChannel.bind(this);
  }
  SendReceive(request: Observable<MessageFromClient>): Observable<MessageToClient> {
    const data = request.pipe(map((request) => MessageFromClient.encode(request).finish()));
    const result = this.rpc.bidirectionalStreamingRequest(this.service, "SendReceive", data);
    return result.pipe(map((data) => MessageToClient.decode(_m0.Reader.create(data))));
  }

  CreateMailbox(request: CreateMailboxRequest): Promise<CreateMailboxResponse> {
    const data = CreateMailboxRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateMailbox", data);
    return promise.then((data) => CreateMailboxResponse.decode(_m0.Reader.create(data)));
  }

  AddChannel(request: AddChannelRequest): Promise<AddChannelResponse> {
    const data = AddChannelRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "AddChannel", data);
    return promise.then((data) => AddChannelResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
  clientStreamingRequest(service: string, method: string, data: Observable<Uint8Array>): Promise<Uint8Array>;
  serverStreamingRequest(service: string, method: string, data: Uint8Array): Observable<Uint8Array>;
  bidirectionalStreamingRequest(service: string, method: string, data: Observable<Uint8Array>): Observable<Uint8Array>;
}

function bytesFromBase64(b64: string): Uint8Array {
  if (globalThis.Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (globalThis.Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
