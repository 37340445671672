import { FC, useMemo } from "react";
import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiToolTip,
} from "@elastic/eui";
import { TitleBarProps } from "./title-bar";

/**
 * @param TitleBarProps default properties needed to display icons in the title bar
 * @param {Function} onWindowClose Callback to parent to handle any additional actions when the window closes
 */
type TitleBarActionsProps = TitleBarProps & {
  onWindowClose: () => void;
};

/**
 * This component handles the display of any action buttons and `close` button,
 * and are positioned in the top right of a draggable window.
 *
 * @param props TitleBarActionsProps
 * @returns Component with action and close buttons
 */
const TitleBarActions: FC<TitleBarActionsProps> = (props) => {
  const { actions, titleColor, onWindowClose } = props;

  const closeButton = useMemo(() => {
    return (
      <div className="close_x_button_box">
        <EuiIcon
          className="close_x_button"
          size={"m"}
          color={"ghost"}
          onClick={onWindowClose}
          aria-label="Close"
          type={"cross"}
          style={{
            cursor: "pointer",
          }}
          // Create a mask that replaces the icon above with the SVG icon of our choice
          // This allows us to manipulate the SVG's color
          // style={{
          //   backgroundColor: color ?? "#ffffff",
          //   WebkitMask: `url(${CloseIcon}) no-repeat center`,
          //   mask: `url(${CloseIcon}) no-repeat center`,
          // }}
        />
      </div>
    );
  }, []);

  return (
    <EuiFlexItem grow={false} className="title-action">
      <EuiFlexGroup alignItems="center" gutterSize="none" responsive={false}>
        {actions?.map((action) => {
          let color: string | undefined = undefined;
          if (!action.disabled) {
            color = titleColor ?? "#FFF";
          }
          return (
            <EuiFlexItem grow={false} key={action.name}>
              <EuiToolTip position="top" content={action.tooltip}>
                <EuiButtonIcon
                  iconType={action.icon}
                  aria-label={action.tooltip}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    action.actionFunction();
                  }}
                  style={{ color: color }}
                  disabled={action.disabled}
                />
              </EuiToolTip>
            </EuiFlexItem>
          );
        })}
        {closeButton}
        {/* <EuiButton  onClick={onWindowClose} /> */}
        {/* <CloseButton color={titleColor ?? "#FFFFFF"} onClick={onWindowClose} /> */}
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};

export default TitleBarActions;
