import { EuiFieldText } from "@elastic/eui";
import { FormInputProps } from "../form-input";

export type TextInputProps = {} & Omit<FormInputProps, "formLayout">;

export const TextInput: React.FC<TextInputProps> = ({
  formItem,
  formData,
  setValue,
}) => {
  const resolvedValue = (): string => {
    const e = formData[formItem.name];
    if (!e) return "";
    else return e as string;
  };
  return (
    <div className="string-input">
      <EuiFieldText
        id={formItem.name}
        value={resolvedValue()}
        onChange={(evt) => setValue(evt.target.value)}
      />
    </div>
  );
};
